import axios from "axios";
import i18n from "../../i18n";

export function getMembers(params) {
    return axios.get("/member", { params: params });
}



export function addExistingUserAsMember(createdBy, first_name, last_name, email, username, status, type, phone = null) {
    const member = {
        first_name: first_name,
        last_name: last_name,
        user_email: email,
        user_phone: phone,
        user_pass: null,
        user_login: username,
        role: 'subscriber',
        status: status,
        type: type,
        addedBy: createdBy
    }
    return axios.post("/member/addMember", member);
}



export function createMember(createdBy, first_name, last_name, email, username, phone = null) {
    const member = {
        first_name: first_name,
        last_name: last_name,
        user_email: email.trim(),
        user_phone: phone,
        user_pass: null,
        user_login: username.trim(),
        role: 'contributor',
        // status: status,
        // type: type,
        addedBy: createdBy
    }
    return axios.post("/member", member);
}

export function updateMember(id, first_name, last_name, email, username, lastStatus: string, phone = null) {
    const member = {
        ID: id,
        first_name: first_name,
        last_name: last_name,
        user_email: email,
        user_phone: phone,
        user_pass: null,
        user_login: username,
        role: 'subscriber',
        // status: status,
        lastStatus: lastStatus,
        // type: type
    }
    return axios.put("/member", member);
}

export function updateBulkStatus(data) {
    return axios.post("/member/status", data);
}

export function deleteMember(ids: any) {
    return axios.post(`/member/delete`, ids);
}

export function getFaqData() {
    const promise = new Promise((resolve, reject) => {
        const faqData = [{
            heading: i18n.t('support.faq.heading1'),
            tabs: [{
                title: i18n.t('support.faq.titles1.one'),
                description: i18n.t('support.faq.descriptions1.one', { returnObjects: true }),
                class: ""
            },
            {
                title: i18n.t('support.faq.titles1.two'),
                description: i18n.t('support.faq.descriptions1.two', { returnObjects: true }),
                class: ""
            },
            {
                title: i18n.t('support.faq.titles1.three'),
                description: i18n.t('support.faq.descriptions1.three', { returnObjects: true }),
                class: ""
            }]
        },
        {
            heading: i18n.t('support.faq.heading2'),
            tabs: [{
                title: i18n.t('support.faq.titles2.one'),
                description: i18n.t('support.faq.descriptions2.one', { returnObjects: true }),
                class: ""
            },
            {
                title: i18n.t('support.faq.titles2.two'),
                description: i18n.t('support.faq.descriptions2.two', { returnObjects: true }),
                class: ""
            },
            {
                title: i18n.t('support.faq.titles2.three'),
                description: i18n.t('support.faq.descriptions2.three', { returnObjects: true }),
                class: ""
            }]
        },
        {
            heading: i18n.t('support.faq.heading3'),
            tabs: [{
                title: i18n.t('support.faq.titles3.one'),
                description: i18n.t('support.faq.descriptions3.one', { returnObjects: true }),
                class: ""
            },
            {
                title: i18n.t('support.faq.titles3.two'),
                description: i18n.t('support.faq.descriptions3.two', { returnObjects: true }),
                class: ""
            },
            {
                title: i18n.t('support.faq.titles3.three'),
                description: i18n.t('support.faq.descriptions3.three', { returnObjects: true }),
                class: ""
            }]
        },
        {
            heading: i18n.t('support.faq.heading4'),
            tabs: [{
                title: i18n.t('support.faq.titles4.one'),
                description: i18n.t('support.faq.descriptions4.one', { returnObjects: true }),
                class: ""
            },
            {
                title: i18n.t('support.faq.titles4.two'),
                description: i18n.t('support.faq.descriptions4.two', { returnObjects: true }),
                class: ""
            },
            {
                title: i18n.t('support.faq.titles4.three'),
                description: i18n.t('support.faq.descriptions4.three', { returnObjects: true }),
                class: ""
            }]
        }];
        setTimeout(() => {
            faqData ? resolve(faqData) : reject('Error');
        }, 1000);
    });

    return promise;
}

export function getHelpData() {
    const promise = new Promise((resolve, reject) => {
        const helpData = [{
            heading: i18n.t('support.help.heading1'),
            tabs: [{
                title: i18n.t('support.help.titles1.one'),
                description: i18n.t('support.help.descriptions1.one', { returnObjects: true }),
                class: ""
            },
            {
                title: i18n.t('support.help.titles1.two'),
                description: i18n.t('support.help.descriptions1.two', { returnObjects: true }),
                class: ""
            },
            {
                title: i18n.t('support.help.titles1.three'),
                description: i18n.t('support.help.descriptions1.three', { returnObjects: true }),
                class: ""
            }]
        },
        {
            heading: i18n.t('support.help.heading2'),
            tabs: [{
                title: i18n.t('support.help.titles2.one'),
                description: i18n.t('support.help.descriptions2.one', { returnObjects: true }),
                class: ""
            },
            {
                title: i18n.t('support.help.titles2.two'),
                description: i18n.t('support.help.descriptions2.two', { returnObjects: true }),
                class: ""
            },
            {
                title: i18n.t('support.help.titles2.three'),
                description: i18n.t('support.help.descriptions2.three', { returnObjects: true }),
                class: ""
            }]
        },
        {
            heading: i18n.t('support.help.heading3'),
            tabs: [{
                title: i18n.t('support.help.titles3.one'),
                description: i18n.t('support.help.descriptions3.one', { returnObjects: true }),
                class: ""
            },
            {
                title: i18n.t('support.help.titles3.two'),
                description: i18n.t('support.help.descriptions3.two', { returnObjects: true }),
                class: ""
            },
            {
                title: i18n.t('support.help.titles3.three'),
                description: i18n.t('support.help.descriptions3.three', { returnObjects: true }),
                class: ""
            }]
        },
        {
            heading: i18n.t('support.help.heading4'),
            tabs: [{
                title: i18n.t('support.help.titles4.one'),
                description: i18n.t('support.help.descriptions4.one', { returnObjects: true }),
                class: ""
            },
            {
                title: i18n.t('support.help.titles4.two'),
                description: i18n.t('support.help.descriptions4.two', { returnObjects: true }),
                class: ""
            },
            {
                title: i18n.t('support.help.titles4.three'),
                description: i18n.t('support.help.descriptions4.three', { returnObjects: true }),
                class: ""
            }]
        },
        {
            heading: i18n.t('support.faq.heading1'),
            tabs: [{
                title: i18n.t('support.faq.titles1.one'),
                description: i18n.t('support.faq.descriptions1.one', { returnObjects: true }),
                class: ""
            },
            {
                title: i18n.t('support.faq.titles1.two'),
                description: i18n.t('support.faq.descriptions1.two', { returnObjects: true }),
                class: ""
            },
            {
                title: i18n.t('support.faq.titles1.three'),
                description: i18n.t('support.faq.descriptions1.three', { returnObjects: true }),
                class: ""
            }]
        },
        {
            heading: i18n.t('support.faq.heading2'),
            tabs: [{
                title: i18n.t('support.faq.titles2.one'),
                description: i18n.t('support.faq.descriptions2.one', { returnObjects: true }),
                class: ""
            },
            {
                title: i18n.t('support.faq.titles2.two'),
                description: i18n.t('support.faq.descriptions2.two', { returnObjects: true }),
                class: ""
            },
            {
                title: i18n.t('support.faq.titles2.three'),
                description: i18n.t('support.faq.descriptions2.three', { returnObjects: true }),
                class: ""
            }]
        },
        {
            heading: i18n.t('support.faq.heading3'),
            tabs: [{
                title: i18n.t('support.faq.titles3.one'),
                description: i18n.t('support.faq.descriptions3.one', { returnObjects: true }),
                class: ""
            },
            {
                title: i18n.t('support.faq.titles3.two'),
                description: i18n.t('support.faq.descriptions3.two', { returnObjects: true }),
                class: ""
            },
            {
                title: i18n.t('support.faq.titles3.three'),
                description: i18n.t('support.faq.descriptions3.three', { returnObjects: true }),
                class: ""
            }]
        },
        {
            heading: i18n.t('support.faq.heading4'),
            tabs: [{
                title: i18n.t('support.faq.titles4.one'),
                description: i18n.t('support.faq.descriptions4.one', { returnObjects: true }),
                class: ""
            },
            {
                title: i18n.t('support.faq.titles4.two'),
                description: i18n.t('support.faq.descriptions4.two', { returnObjects: true }),
                class: ""
            },
            {
                title: i18n.t('support.faq.titles4.three'),
                description: i18n.t('support.faq.descriptions4.three', { returnObjects: true }),
                class: ""
            }]
        }];
        setTimeout(() => {
            helpData ? resolve(helpData) : reject('Error');
        }, 1000);
    });

    return promise;
}