import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import Layout from "../../../_metronic/layout/Layout";

const Connections = lazy(() =>
    import("./Connections")
);

const Profile = lazy(() =>
    import("./Profile")
);

const Google = lazy(() =>
    import("./connections/Google")
);

const Plans = lazy(() =>
    import("./Plans")
);

const Review = lazy(() =>
    import("./Review")
);

export default function GetStartedPage() {
    return (
        <Layout>
            <Suspense fallback={<LayoutSplashScreen />}>
                <Switch>
                    <Redirect from="/get-started/" exact={true} to="/get-started/profile" />
                    <Route path="/get-started/plans" component={Plans} />
                    <Route path="/get-started/profile" component={Profile} />
                    <Route path="/get-started/review" component={Review} />
                    <Route path="/get-started/connections/google" component={Google} />
                    <Route path="/get-started/connections" component={Connections} />
                </Switch>
            </Suspense>
        </Layout>
    );
}
