import React, { useState } from "react";

export const SelectedRowContext = React.createContext();

const SelectedRowProvider = (props) => {
    const [selectedRowData, setSelectedRowData] = useState({});
    return <SelectedRowContext.Provider value={{ selectedRowData, setSelectedRowData }}>{props.children}</SelectedRowContext.Provider>;
};

export default SelectedRowProvider;
