import axios from "axios";
import { User } from "../store/auth";


export function login(email: string, password: string, remember) {
  return axios.post("users/login", { email, password, remember });
}

export function logout() {
  return axios.post("users/logout");
}

export function register(firstName: string, lastName: string, email: string, password: string, ref_by: string | null) {
  return axios.post("users/register", { first_name: firstName, last_name: lastName, email, password, ref_by });
}

export function requestPassword(email: string) {
  return axios.post("users/forgot_password", { email });
}

export function resetPassword(key: string, secret: string, password: string) {
  return axios.post("users/reset_password", { key, secret, password });
}

export function getMe() {
  return axios.get("users/id");
}

export function updateMe(user: User, type: any = null) {
  return axios.put("users/id", { user: user, type: type });
}

export function addTeamMember(params: { first_name: string, last_name: string, email: string }) {
  return axios.post("users/team", params)
}

export function getTeamMember() {
  return axios.get("users/team")
}

export function deleteTeamMember(id: number) {
  return axios.delete(`users/team/${id}`)
}

export function getUserMeta() {
  return axios.get("users/id/meta");
}

export function updateCustomMeta(params) {
  return axios.put("users/id/meta", params);
}

export function updateUserMeta(id, params) {
  return axios.put(`users/meta/other/${id}`, params);
}

export function customLogin(params) {
  return axios.get("users/custom_login", { params });
}

export function getAllUsers(params) {
  return axios.get("users/", { params });
}

export function feedback(params) {
  return axios.post("users/id/feedback", params);
}

export function wishFunction(params) {
  return axios.post("users/id/wish", params);
}

export function vatValidate(id) {
  return axios.post("vat/check", { vat: id });
}