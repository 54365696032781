import { useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactSortable } from "react-sortablejs";
import { uploadFileWithProgress, uploadThumb, uploadVideo } from "src/app/crud/files.crud";
import { uploadImageOnMeta } from "src/app/crud/meta.crud";
import { uploadImageOnTaboola } from "src/app/crud/taboola.crud";
import { uploadMediaOnTiktok } from "src/app/crud/tiktok.crud";
import { uploadImageOnTwitter, uploadVideoOnTwitter } from "src/app/crud/twitter.crud";
import { getThumbnailForVideo } from "src/app/utils/common";

import { IconButton } from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import RemoveIcon from "@material-ui/icons/Remove";

import CompanyModalStyle from "../../pages/sales-connect/style/CompanyModalStyle";
import MediaCropper from "./MediaCropper";
import { toAbsoluteUrl } from "src/_metronic";
import ImageCropModal from "src/app/pages/editor/helpers/ImageCropModal";

export const UploadedFrom = {
    SUPPORT: "support",
    CAMPAIGN: "campaign",
    MEDIA: "media",
    PRODUCT_FEED: "productFeed",
    CHAT: "chat",
    PROFILE: "profile",
    POSTWIZARD: "postWizard",
    ADSPACE: "adspace",
};

const imgType = ["image/jpeg", "image/jpg", "image/png"];

type iDropZone = {
    getImages: any;
    imageDisplay?: boolean;
    removeAll?: boolean;
    uploadedFrom: string;
    uploadFor?: string;
    uploadText?: string;
    label?: any;
    adType?: string;
    clearFiles?: any;
};

const DropZone = ({
    getImages,
    imageDisplay = true,
    removeAll,
    uploadedFrom,
    uploadFor = "",
    uploadText = undefined,
    label,
    adType,
    clearFiles,
}: iDropZone) => {
    const companyClasses = CompanyModalStyle();
    const { t } = useTranslation();
    const { setFieldValue }: any = useFormikContext() ?? {};
    const [selectedFiles, setSelectedFiles] = useState<any>([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [activeImage, setActiveImage] = useState(-1);
    const [activeText, setActiveText] = useState(-1);
    const [validFiles, setValidFiles] = useState<any>([]);
    const [unsupportedFiles, setUnsupportedFiles] = useState<any>([]);
    const [verticalImgList, setVerticalList] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openAdsModal, setOpenAdsModal] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<any>(null);
    const [multipleFilesError, setMultipleFilesError] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef<any>();
    const getVideoUrl = [
        {
            thumbnailUrl: "",
            videoUrl: "",
        },
    ];

    const fileSize = (size) => {
        //File Type Method
        if (size === 0) return "0 Bytes";
        const k = 1024;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    };

    const validateFile = (file) => {
        //File Vlaidate Method
        const validTypes = [
            "image/jpeg",
            "image/jpg",
            "image/png",
            "image/gif",
            "image/x-icon",
            "video/mp4",
            "video/quicktime",
        ];

        const adspaceVideoFormats = [
            "video/avi",
            "video/wmv",
            "video/webm",
            "video/h264",
            "video/mpeg4"
        ];

        if (uploadedFrom === UploadedFrom.SUPPORT) validTypes.push("application/pdf", "text/plain");
        if (uploadedFrom === UploadedFrom.ADSPACE) {
            if (adType === "image") {
                return validTypes.slice(0, 5).includes(file.type);
            } else if (adType === "video") {
                return validTypes.slice(5).concat(adspaceVideoFormats).includes(file.type);
            }
        }
        if (validTypes.indexOf(file.type) === -1) {
            return false;
        }
        return true;
    };

    const upload = async (idx, file) => {
        const config = {};

        if (file.type === "video/mp4" || file.type === "video/quicktime" || file.type === "video/avi" || file.type === "video/wmv" || file.type === "video/webm" || file.type === "video/h264" || file.type === "video/mpeg4" ) {
            await uploadVideoService(file, config)
                .then((data: any) => {
                    if (data) {
                        file.isUploading = "completed";
                        file.fileUrl = data.data.url;
                    }
                })
                .catch((err) => {
                    file.isUploading = "error";
                    if (
                        err.message.includes("400") ||
                        err.message.includes("413")
                    ) {
                        file.errorMesage = "postWizard.mediaErrorMsg";
                    } else {
                        file.errorMesage = "media.errorMsg";
                    }
                });
        } else {
            if (
                uploadFor === "facebook" ||
                uploadFor === "linkedin" ||
                uploadFor === "taboola" ||
                uploadFor === "twitter" ||
                uploadFor === "tiktok"
            ) {
                await uploadImageService(file, config)
                    .then((data: any) => {
                        if (uploadFor === "facebook") {
                            if (data?.data) {
                                file.isUploading = "completed";
                                file.fileUrl = data.data.images.bytes.url;
                                file.hash = data.data.images.bytes.hash;
                            }
                        } else if (uploadFor === "linkedin") {
                            if (data?.data) {
                                file.isUploading = "completed";
                                file.fileUrl = data.data.url;
                            }
                        } else if (uploadFor === "taboola") {
                            if (data?.data) {
                                file.isUploading = "completed";
                                file.fileUrl = data.data.value;
                            }
                        } else if (uploadFor === "twitter") {
                            if (data?.data) {
                                file.isUploading = "completed";
                                file.media_key = data.data.media_key;
                                file.fileUrl = data.data.url;
                            }
                        } else if (uploadFor === "tiktok") {
                            if (data?.data) {
                                file.isUploading = "completed";
                                file.media_key = data?.data?.data[0]?.video_id;
                                file.thumbanail_url = data?.data?.data[0]?.video_cover_url;
                                file.preview_url = data?.data?.data[0]?.preview_url;
                            }
                        }
                    })
                    .catch((err) => {
                        file.isUploading = "error";
                        file.errorMesage = err.message;
                        if (err && uploadFor === "taboola") {
                            file.isUploading = "error";
                            file.errorMesage1 = "integrations.taboola.imageErr1";
                            file.errorMesage2 = "integrations.taboola.imageErr2";
                        } else {
                            file.isUploading = "error";
                            if (
                                err.message.includes("400") ||
                                err.message.includes("413")
                            ) {
                                file.errorMesage = "postWizard.mediaErrorMsg";
                            } else {
                                file.errorMesage = "media.errorMsg";
                            }
                        }
                    });
            } else {
                await uploadImageService(file, config)
                    .then((data: any) => {
                        if (data) {
                            file.isUploading = "completed";
                            file.fileUrl = data.data.url;
                        }
                    })
                    .catch((err) => {
                        file.isUploading = "error";
                        if (
                            err.message.includes("400") ||
                            err.message.includes("413")
                        ) {
                            file.errorMesage = "postWizard.mediaErrorMsg";
                        } else {
                            file.errorMesage = "media.errorMsg";
                        }
                    });
            }
        }

        return file;
    };

    // const handleFiles = (files) => {
    //     //Helper Method

    //     // let _progressInfos: any = [];
    //     for (let i = 0; i < files.length; i++) {
    //         files[i]["invalid"] = false;
    //         files[i]["isUploading"] = "";
    //         if (validateFile(files[i])) {
    //             // add to an array so we can display the name of file
    //             setSelectedFiles((prevArray) => [...prevArray, files[i]]);
    //         } else {
    //             // add a new property called invalid
    //             files[i]["invalid"] = true;
    //             // add to the same array so we can display the name of the file
    //             setSelectedFiles((prevArray) => [...prevArray, files[i]]);
    //             // set error message
    //             setErrorMessage("postWizard.mediaTypeMsg");

    //             setUnsupportedFiles((prevArray) => [...prevArray, files[i]]);
    //         }
    //     }
    // };

    const handleFiles = (files) => {
        // Helper Method
        for (let i = 0; i < files.length; i++) {
            // Reset properties for each file
            files[i]["invalid"] = false;
            files[i]["isUploading"] = "";

            if (uploadedFrom === UploadedFrom.ADSPACE) {
                if (selectedFiles.length === 0) {
                    if (validateFile(files[i])) {
                        setSelectedFiles([files[i]]);
                        setMultipleFilesError("");
                    } else {
                        files[i]["invalid"] = true;
                        setSelectedFiles([files[i]]);
                        setErrorMessage(
                            adType === "image"
                                ? "adSpace.imageMediaError"
                                : "adSpace.videoMediaError"
                        );
                        setUnsupportedFiles((prevArray) => [...prevArray, files[i]]);
                    }
                } else {
                    setMultipleFilesError(t('You can only upload one file in your adspace.'));
                    setTimeout(() => {
                        setMultipleFilesError("");
                    }, 3000);
                    return;
                }
            } else {
                if (validateFile(files[i])) {
                    setSelectedFiles((prevArray) => [...prevArray, files[i]]);
                    setMultipleFilesError("");
                } else {
                    files[i]["invalid"] = true;
                    setSelectedFiles((prevArray) => [...prevArray, files[i]]);
                    setErrorMessage("postWizard.mediaTypeMsg");
                    setUnsupportedFiles((prevArray) => [...prevArray, files[i]]);
                }
            }
        }
    };


    const dragOver = (e) => {
        e.preventDefault();
    };

    const dragEnter = (e) => {
        e.preventDefault();
    };

    const dragLeave = (e) => {
        e.preventDefault();
    };

    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length) {
            const selectedFile = files[0];
            const fileType = selectedFile.type;
            if (uploadedFrom === UploadedFrom.ADSPACE && fileType.startsWith("image/") && adType === "image") {
                // Open modal only for images
                setOpenAdsModal(true);
                setSelectedFile(selectedFile);
            } else {
                // Default behavior for other cases
                handleFiles(files);
            }
        }
    };

    useEffect(() => {
        let filteredArray = selectedFiles.reduce((file, current) => {
            const x = file.find((item) => item.name === current.name);
            if (!x) {
                return file.concat([current]);
            } else {
                return file;
            }
        }, []);
        setValidFiles([...filteredArray]);
    }, [selectedFiles]);

    useEffect(() => {
        if (removeAll === true) {
            setValidFiles([]);
            setSelectedFiles([]);
            fileInputRef.current.value = null;
        }
    }, [removeAll]);

    const removeFile = (e, name) => {
        e.stopPropagation();
        fileInputRef.current.value = null;

        const validFileIndex = validFiles.findIndex((e) => e.name === name);
        validFiles.splice(validFileIndex, 1);
        setValidFiles([...validFiles]);

        const verticalImageListIndex = verticalImgList.findIndex((e) => e.name === name);
        verticalImgList.splice(verticalImageListIndex, 1);
        setVerticalList([...verticalImgList]);

        const selectedFileIndex = selectedFiles.findIndex((e) => e.name === name);
        selectedFiles.splice(selectedFileIndex, 1);
        setSelectedFiles([...selectedFiles]);

        const unsupportedFileIndex = unsupportedFiles.findIndex((e) => e.name === name);
        if (unsupportedFileIndex !== -1) {
            unsupportedFiles.splice(unsupportedFileIndex, 1);
            setUnsupportedFiles([...unsupportedFiles]);
        }

        setTimeout(() => {
            getImages(validFiles);
        }, 100);
    };

    const fileInputClicked = (e) => {
        if (e.target.getAttribute("id") === "edit_image") {
            e.stopPropagation();
        } else {
            fileInputRef.current.click();
        }
    };

    // const filesSelected = () => {
    //     if (fileInputRef.current.files.length) {
    //         handleFiles(fileInputRef.current.files);
    //     }
    // };

    const filesSelected = () => {
        if (fileInputRef.current.files.length) {
            const selectedFile = fileInputRef.current.files[0];
            const fileType = selectedFile.type;
            if (uploadedFrom === UploadedFrom.ADSPACE && fileType.startsWith("image/") && adType === "image") {
                    // Open modal only for images
                    setOpenAdsModal(true);
                    setSelectedFile(selectedFile);
            } else {
                // Default behavior for other cases
                handleFiles(fileInputRef.current.files);
            }
        }
    };

    const uploadImageService = async (currentFile, onUploadProgress) => {
        const formData = new FormData();
        formData.append("file", currentFile);
        if (uploadFor === "facebook") {
            return uploadImageOnMeta(formData, onUploadProgress);
        }
        if (uploadFor === "taboola") {
            return uploadImageOnTaboola(formData, onUploadProgress);
        }
        if (uploadFor === "twitter") {
            const imgUrl = await uploadFileWithProgress(formData, onUploadProgress, uploadedFrom);
            const twitterImg = await uploadImageOnTwitter(formData, onUploadProgress);
            if (imgUrl?.data) twitterImg.data.url = imgUrl.data?.url;
            return twitterImg;
        }
        if (uploadFor === "tiktok") {
            return uploadMediaOnTiktok(formData, onUploadProgress);
        }

        return uploadFileWithProgress(formData, onUploadProgress, uploadedFrom);
    };

    const uploadVideoService = async (currentFile, onUploadProgress) => {
        const formData = new FormData();
        formData.append("video", currentFile);
        const fileUrl = URL.createObjectURL(currentFile);
        const thumbnail = await getThumbnailForVideo(fileUrl);
        uploadThumb("thumb_" + currentFile.name, thumbnail).then((res) => (getVideoUrl[0].thumbnailUrl = res.data.url));
        const uploadedVideo = uploadVideo(formData);
        await uploadedVideo.then((res) => {
            getVideoUrl[0].videoUrl = res.data.url;
            if (uploadFor === "twitter") {
                uploadVideoOnTwitter({ url: res.data.url }).then((resp) => {
                    getVideoUrl[0].videoUrl = resp.data.mediaKey;
                    setFieldValue("videoUrl", getVideoUrl);
                });
            } else {
                setFieldValue("videoUrl", getVideoUrl);
            }
        });
        return uploadedVideo;
    };

    const imageReader = (file: any) => {
        return URL.createObjectURL(file);
    };

    useEffect(() => {
        if (validFiles && validFiles.length > 0) {
            if (!imageDisplay) {
                setLoading(true);
            }
            for (let i = 0; i < validFiles.length; i++) {
                if (validFiles[i].isUploading === "" && validFiles[i].invalid === false) {
                    validFiles[i].isUploading = "started";
                    upload(i, validFiles[i])
                        .then((data) => {
                            validFiles[i] = data;
                            verticalImgList.push({ fileUrl: data.fileUrl, name: data.name, size: data.size });
                            verticalImgList.push({
                                fileUrl: data.fileUrl,
                                name: data.name,
                                size: data.size,
                                isUploading: data.isUploading,
                                errorMesage1: data.errorMesage1,
                                errorMesage2: data.errorMesage2,
                            });
                            setVerticalList([...verticalImgList]);
                            getImages(validFiles);
                        })
                        .catch((err) => {
                            setVerticalList(verticalImgList);
                            getImages(validFiles);
                        });
                }
            }
        } else {
            getImages(validFiles);
            setVerticalList(verticalImgList);
        }
        // eslint-disable-next-line
    }, [validFiles]);

    useEffect(() => {
        if (validFiles.length === verticalImgList.length) {
            setLoading(false);
        }
    }, [validFiles, verticalImgList]);

    useEffect(() => {
        if (adType) {
            setValidFiles([]);
            setSelectedFiles([]);
            setUnsupportedFiles([]);
            setErrorMessage("");
            setMultipleFilesError("");
            fileInputRef.current.value = null;
        }
    }, [adType]);

    useEffect(() => {
        if (clearFiles) {
            setValidFiles([]);
            setSelectedFiles([]);
            setUnsupportedFiles([]);
            setErrorMessage("");
            setMultipleFilesError("");
            fileInputRef.current.value = null;
        }
    }, [clearFiles]);


    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="form">
                        {label ? (
                            <label className="form-label">{label}</label>
                        ) : (
                            <label className="form-label">{t("support.help.ticketForm.attachments")}</label>
                        )}
                    </div>
                    <div className="form-group">
                        <div
                            className="dropzone-container dz-clickable"
                            onClick={(e) => fileInputClicked(e)}
                            onDragOver={dragOver}
                            onDragEnter={dragEnter}
                            onDragLeave={dragLeave}
                            onDrop={fileDrop}
                            id="kt_modal_create_ticket_attachments"
                        >
                            <div
                                className="dz-message needsclick align-items-center"
                                style={loading ? { justifyContent: "center" } : {}}
                            >
                                {loading ? null : imageDisplay && validFiles.length > 0 ? null : (
                                    <span className="svg-icon svg-icon-3hx svg-icon-primary">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="#009ef7"
                                        >
                                            <path
                                                opacity="0.3"
                                                d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 12.6L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L8 12.6H11V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18V12.6H16Z"
                                                fill="black"
                                            ></path>
                                            <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black"></path>
                                        </svg>
                                    </span>
                                )}

                                <div className={loading ? "ms-0" : "ms-4"}>
                                    {loading ? (
                                        <div className="spinner-border text-dark"></div>
                                    ) : imageDisplay && validFiles.length > 0 ? null : (
                                        <>
                                            <h3 className="fs-5 fw-bolder text-gray-900 mb-1">
                                                {uploadedFrom === UploadedFrom.SUPPORT
                                                    ? t("support.help.ticketForm.supportUploadText")
                                                    : t("support.help.ticketForm.attachmentText")
                                                }
                                            </h3>
                                            {/* <span className="fw-bold fs-7 text-gray-400">
                                                {uploadText ? uploadText : t("support.help.ticketForm.uploadText")}
                                            </span> */}
                                            {uploadedFrom === UploadedFrom.ADSPACE ? (
                                                <span>Upload only single files</span>
                                            ) : (
                                                <span>{uploadText ? uploadText : t("support.help.ticketForm.uploadText")}</span>
                                            )}
                                        </>
                                    )}
                                    <input
                                        ref={fileInputRef}
                                        className="image-file-input"
                                        type="file"
                                        multiple={uploadedFrom !== UploadedFrom.ADSPACE}
                                        onChange={filesSelected}
                                    />
                                </div>
                            </div>

                            {imageDisplay &&
                                validFiles &&
                                validFiles.map((data, i) => (
                                    <div
                                        key={i}
                                        onMouseEnter={() => setActiveImage(i)}
                                        // onHoverImage(i, true)
                                        onMouseLeave={() => setActiveImage(-1)}
                                        className="dz-preview dz-image-preview dz-processing dz-error dz-complete"
                                    >
                                        <div className={i === activeImage ? "dz-image img-blur" : "dz-image"}>
                                            {data.type === "video/mp4" || data.type === "video/quicktime" ? (
                                                <video width="100%" height="100%">
                                                    <source src={imageReader(data)} type="video/mp4" />
                                                </video>
                                            ) : data.type === "application/pdf" ? (
                                                <img alt={data.name} src={toAbsoluteUrl("/media/files/pdf.svg")} />
                                            ) : data.type === "text/plain" ? (
                                                <img alt={data.name} src={toAbsoluteUrl("/media/files/txt.svg")} />
                                            ) : (
                                                <img width="120px" height="120px" style={{ objectFit: "contain" }} alt={data.name} src={imageReader(data)} />
                                            )}
                                        </div>
                                        <div className="dz-details" style={i === activeImage ? { opacity: 1 } : {}}>
                                            <div className="dz-size">
                                                <span data-dz-size="">
                                                    <strong>{fileSize(data.size)}</strong>
                                                </span>
                                            </div>
                                            <div
                                                onMouseEnter={() => setActiveText(i)}
                                                onMouseLeave={() => setActiveText(-1)}
                                                className="dz-filename"
                                                style={i === activeText ? { overflow: "visible" } : {}}
                                            >
                                                <span data-dz-name="">{data.name}</span>
                                            </div>
                                        </div>
                                        {(data.isUploading === "started" || data.isUploading === "") &&
                                            data.invalid === false ? (
                                            <div className="spinner-border text-dark"></div>
                                        ) : null}
                                        {data.isUploading === "error" || data.invalid === true ? (
                                            <div
                                                className="dz-error-message"
                                                style={{ opacity: i === activeImage ? 1 : 0 }}
                                            >
                                                <span data-dz-errormessage="">
                                                    {data.invalid === true ? t(errorMessage) : t(data.errorMesage)}
                                                </span>
                                            </div>
                                        ) : null}

                                        <div
                                            className="dz-success-mark"
                                            style={{ opacity: data.isUploading === "completed" ? 1 : 0 }}
                                        >
                                            <svg
                                                width="54px"
                                                height="54px"
                                                viewBox="0 0 54 54"
                                                version="1.1"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <title>Check</title>
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <path
                                                        d="M23.5,31.8431458 L17.5852419,25.9283877 C16.0248253,24.3679711 13.4910294,24.366835 11.9289322,25.9289322 C10.3700136,27.4878508 10.3665912,30.0234455 11.9283877,31.5852419 L20.4147581,40.0716123 C20.5133999,40.1702541 20.6159315,40.2626649 20.7218615,40.3488435 C22.2835669,41.8725651 24.794234,41.8626202 26.3461564,40.3106978 L43.3106978,23.3461564 C44.8771021,21.7797521 44.8758057,19.2483887 43.3137085,17.6862915 C41.7547899,16.1273729 39.2176035,16.1255422 37.6538436,17.6893022 L23.5,31.8431458 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z"
                                                        strokeOpacity="0.198794158"
                                                        stroke="#747474"
                                                        fillOpacity="0.816519475"
                                                        fill="#FFFFFF"
                                                    ></path>
                                                </g>
                                            </svg>
                                        </div>
                                        {data.isUploading === "error" || data.invalid === true ? (
                                            <div className="dz-error-mark">
                                                <svg
                                                    width="54px"
                                                    height="54px"
                                                    viewBox="0 0 54 54"
                                                    version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    {" "}
                                                    <title>Error</title>
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <g
                                                            stroke="#747474"
                                                            strokeOpacity="0.198794158"
                                                            fill="#FFFFFF"
                                                            fillOpacity="0.816519475"
                                                        >
                                                            <path d="M32.6568542,29 L38.3106978,23.3461564 C39.8771021,21.7797521 39.8758057,19.2483887 38.3137085,17.6862915 C36.7547899,16.1273729 34.2176035,16.1255422 32.6538436,17.6893022 L27,23.3431458 L21.3461564,17.6893022 C19.7823965,16.1255422 17.2452101,16.1273729 15.6862915,17.6862915 C14.1241943,19.2483887 14.1228979,21.7797521 15.6893022,23.3461564 L21.3431458,29 L15.6893022,34.6538436 C14.1228979,36.2202479 14.1241943,38.7516113 15.6862915,40.3137085 C17.2452101,41.8726271 19.7823965,41.8744578 21.3461564,40.3106978 L27,34.6568542 L32.6538436,40.3106978 C34.2176035,41.8744578 36.7547899,41.8726271 38.3137085,40.3137085 C39.8758057,38.7516113 39.8771021,36.2202479 38.3106978,34.6538436 L32.6568542,29 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z"></path>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                        ) : null}
                                        <button
                                            className="dz-remove"
                                            type="button"
                                            onClick={(e) =>
                                                data.isUploading !== "started" || data.invalid
                                                    ? removeFile(e, data.name)
                                                    : null
                                            }
                                        >
                                            Remove file
                                        </button>
                                        {data.isUploading === "completed" &&
                                            imgType.includes(data.type) && uploadedFrom !== UploadedFrom.ADSPACE &&  (
                                                <button
                                                    className="dz-edit"
                                                    id="edit_image"
                                                    type="button"
                                                    onClick={() => { setOpenModal(true); setSelectedImage(data); }}
                                                >
                                                    <i className="fa fa-pen" id="edit_image"></i>
                                                </button>
                                            )}
                                    </div>
                                ))}
                        </div>

                        {!imageDisplay ? (
                            <div className="mb-10">
                                {verticalImgList && verticalImgList.length > 0 ? (
                                    <label className="fs-6 form-label fw-bold mb-2">Uploaded File</label>
                                ) : null}
                                <ReactSortable
                                    list={verticalImgList}
                                    setList={setVerticalList}
                                    handle=".handle-icon"
                                    animation={150}
                                >
                                    {verticalImgList &&
                                        verticalImgList.map((item: any, idx) => (
                                            <div className={companyClasses.contactPersonWrapper} key={idx}>
                                                <div className="d-flex flex-stack py-4 border border-top-0 border-left-0 border-right-0 border-dashed">
                                                    <div className="d-flex align-items-center">
                                                        <div className="symbol symbol-35px">
                                                            {item.fileUrl || item.thumbanail_url ? (
                                                                <img
                                                                    alt={item.name}
                                                                    src={item.fileUrl || item.thumbanail_url}
                                                                />
                                                            ) : item.type === "video/mp4" ||
                                                                item.type === "video/quicktime" ? (
                                                                <video
                                                                    src={imageReader(validFiles[idx])}
                                                                    width="100%"
                                                                    height="100%"
                                                                >
                                                                    <source
                                                                        src={imageReader(validFiles[idx])}
                                                                        type="video/mp4"
                                                                    />
                                                                </video>
                                                            ) : (
                                                                <img width="120px" height="120px" style={{ objectFit: "contain" }} alt={item.name} src={imageReader(validFiles[idx])} />
                                                            )}
                                                        </div>
                                                        <div style={{ paddingLeft: "15px" }} className="ms-6">
                                                            <span className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2">
                                                                {item.name}
                                                            </span>
                                                            <div className="fw-bold text-muted">{fileSize(item.size)}</div>
                                                        </div>
                                                    </div>
                                                    <Col className="text-right">
                                                        <IconButton
                                                            disabled={verticalImgList.length === 1}
                                                            size="small"
                                                            className="mb-2 handle-icon"
                                                        >
                                                            <DragIndicatorIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            // disabled={state.length === 1}
                                                            onClick={(e) => removeFile(e, item.name)}
                                                            size="small"
                                                            className="mb-2"
                                                        >
                                                            <RemoveIcon />
                                                        </IconButton>
                                                    </Col>
                                                </div>
                                            </div>
                                        ))}
                                </ReactSortable>
                            </div>
                        ) : null}
                    </div>
                    {multipleFilesError && <span className="text-danger mt-3">{multipleFilesError}</span>}
                </div>
            </div >
            <ImageCropModal
                    show={openAdsModal}
                    onClose={(value) => {
                        setOpenAdsModal(value);
                        fileInputRef.current.value = null;
                    }}
                    selectedFile={selectedFile}
                    onCropComplete={(value) => handleFiles([value])}
                    uploadText={uploadText}
            />
            <MediaCropper
                show={openModal}
                onClose={(value) => { setOpenModal(value); setSelectedImage(null); }}
                selectedFile={selectedImage}
                onCropComplete={(e, value) => { removeFile(e, selectedImage.name); handleFiles([value]) }}
            />
        </>
    );
};
export default DropZone;
