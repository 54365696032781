import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import { useDispatch, useSelector } from "react-redux";
import { logout as logoutAction } from "../../store/auth";
import { reset as resetAction } from "../../store/data";
import { resetActivity } from "src/app/store/activity/activity.actions";


export default function Logout() {

  const dispatch = useDispatch();
  const authenticated = useSelector((state: any) => state.auth.user !== null ? true : false);

  useEffect(() => {
    dispatch(resetAction());
    dispatch(resetActivity());
    dispatch(logoutAction());
  });

  return (
    <>
      {authenticated ? <LayoutSplashScreen /> : <Redirect to="/auth" />}
    </>
  );
}


