import React from "react";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";

// let linkedinCheck = false;
// let socialAdsCheck = false;
export default class MenuList extends React.Component {
    //   checkLindeinPlan = (user) => {
    //     if (user && user.sub && user.sub.line_items) {
    //       user.sub.line_items.forEach((rec) => {
    //         if (rec.name.includes("Business Ads")) {
    //           linkedinCheck = true;;
    //         }
    //         if (rec.name.includes("Social Ads")) {
    //           socialAdsCheck = true;;
    //         }
    //       });
    //     }
    //   }
    render() {
        const { currentUrl, menuConfig, layoutConfig, userData } = this.props;
        // this.checkLindeinPlan(userData);
        return menuConfig.aside.items.map((child, index) => {
            //   if (child.title === "linkedinAds" && !linkedinCheck) {
            //     return null;
            //   }
            //   if (child.title === "metaAds" && !socialAdsCheck) {
            //     return null;
            //   } else {
            return (
                <React.Fragment key={`menuList${index}`}>
                    {child.section && <MenuSection item={child} />}
                    {child.separator && <MenuItemSeparator item={child} />}
                    {child.title && (
                        <MenuItem
                            userData={userData}
                            item={child}
                            currentUrl={currentUrl}
                            layoutConfig={layoutConfig}
                        />
                    )}
                </React.Fragment>
            );

            //   }
        });
    }
}
