import axios from "axios";

export function postQualification(payload) {
    return axios.post("/users/id/qualification", payload);
}

export function updateQualification(payload) {
    return axios.put(`/users/id/qualification/update`, payload);
}

export function updateProfileQualification(payload) {    
    return axios.put(`/users/id/profile/qualification/update`, payload);
}