import React, { useState } from "react";
import ReactCrop from "react-image-crop";
import { Button, Modal, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import "react-image-crop/dist/ReactCrop.css";

export default function ImageCropModal({ show, onClose, selectedFile, onCropComplete, uploadText }) {

    const { t } = useTranslation();

    const [errorMsg, setErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [imageRef, setImageRef] = useState<any>();
    const [croppedImage, setCroppedImage] = useState<any>();
    const Adspace = uploadText === "adspace" ? {
        unit: "px",
        width: 336,
        height: 512,
        x: 0,
        y: 0,
    } : {
        unit: "px",
        width: 100,
        height: 100,
        x: 0,
        y: 0,
    }
    const [crop, setCrop] = useState<any>(Adspace)

    const onCropChange = (crop) => {
        setCrop(crop);
    };

    const OnImageLoaded = (event: any) => {
        const image = event.target;
        setImageRef(image);
    };

    const handleCropComplete = async (crop) => {
        if (imageRef && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(
                imageRef,
                crop,
                selectedFile
            );
            setCroppedImage(croppedImageUrl);
        }
    };

    const getCroppedImg = (image, crop, selectedFile) => {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx: any = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob: any) => {
                if (!blob) {
                    reject(new Error("Canvas is empty"));
                    return;
                }
                blob.name = selectedFile.name;
                const croppedFile = new File([blob], selectedFile.name, { type: selectedFile.type });
                resolve(croppedFile);
            }, "image/jpeg");
        });
    };

    const Form = useFormik({
        initialValues: {
            image: []
        },
        onSubmit: async (values) => {
            try {
                onCropComplete(croppedImage);
                setImageRef(null);
                setCroppedImage(null);
                Form.resetForm();
                onClose(false);
            } catch (error) {
                setErrorMsg(t('general.error'));
            }
        }
    });

    return (
        <Modal size="lg" show={show} onHide={() => Form.isSubmitting ? null : onClose(false)} scrollable={true}>
            <Modal.Header closeButton={Form.isSubmitting ? false : true}>
                <Modal.Title>Crop Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert show={successMsg ? true : false} variant="success" onClose={() => setSuccessMsg("")} dismissible>
                    {successMsg}
                </Alert>

                <Alert show={errorMsg ? true : false} variant="danger" onClose={() => setErrorMsg("")} dismissible>
                    {errorMsg}
                </Alert>

                <div className="text-center">
                    <ReactCrop
                        crop={crop}
                        onChange={onCropChange}
                        onComplete={handleCropComplete}
                        aspect={(uploadText === "landscapeMarketingImage" || uploadText === "landscapeMediaImage" )  ? 1.91 / 1 : uploadText !== "adspace" ? 1 : undefined}
                        minWidth={
                            (uploadText === "squareMarketingImage" || uploadText === "squareMediaImage")
                                ? 300
                                : (uploadText === "landscapeMarketingImage" || uploadText === "landscapeMediaImage")
                                    ? 600
                                    : (uploadText === "adspace") ? 336 : 128
                        }
                        minHeight={
                            (uploadText === "squareMarketingImage" || uploadText === "squareMediaImage")
                                ? 300
                                : (uploadText === "landscapeMarketingImage" || uploadText === "landscapeMediaImage")
                                    ? 314
                                    : (uploadText === "adspace") ? 512 : 128
                        }
                        maxHeight={(uploadText === "adspace") ? 512 : undefined}
                        maxWidth={(uploadText === "adspace") ? 336 : undefined}
                    >
                        {selectedFile && selectedFile instanceof File && (
                            <img
                                height="100%"
                                width="100%"
                                src={URL.createObjectURL(selectedFile)}
                                alt=""
                                onLoad={OnImageLoaded}
                            />
                        )}
                    </ReactCrop>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="text-left" style={{ flex: 1 }}>
                    <label>Width :</label>
                    <span>{" " + crop.width.toFixed(2) + " " + (crop.unit).toUpperCase()}</span>
                    <br />
                    <label>Height :</label>
                    <span>{" " + crop.height.toFixed(2) + " " + (crop.unit).toUpperCase()}</span>
                </div>
                <div className="d-flex justify-content-end" style={{ gap: "10px" }}>
                    <Button
                        disabled={Form.isSubmitting}
                        className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                        onClick={() => onClose(false)}
                        variant="secondary"
                    >
                        {t('general.cancel')}
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                        onClick={Form.submitForm}
                        disabled={Form.isSubmitting}
                    >
                        Upload
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}