/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React, { useContext, useEffect } from "react";
import { Redirect, Route, Switch, withRouter, useLocation } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import { setCookie } from "../../_metronic/utils/utils";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "./RouterHelpers";
import AuthPage from "../pages/auth/AuthPage";
import GetStartedPage from "../pages/get-started/GetStartedPage";
import LoggedInRoutes from "./LoggedInRoutes";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import ScrollToTop from "./ScrollToTop";
import { setLanguage } from "../store/data";
import { LoginAsContext } from "../context/LoginContext";

// TODO: Typecheck
interface RouterState {
    auth: any;
    urls: any;
    builder: {
        menuConfig: any;
    };
    data: any;
}

export const Routes = withRouter(({ history }) => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const lastLocation = useLastLocation();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    const { userType } = useContext(LoginAsContext);

    routerHelpers.saveLastLocation(lastLocation);

    useEffect(() => {
        if (queryParams.ref_by) {
            setCookie("ref_by", queryParams.ref_by, 30);
        }

        if (queryParams.lng && ["de", "en"].includes(queryParams.lng as string)) {
            dispatch(setLanguage(queryParams.lng as string));
        } else {
            if (i18n.language.indexOf("en-") > -1) dispatch(setLanguage("en"));
            else if (i18n.language.indexOf("de-") > -1) dispatch(setLanguage("de"));
            else dispatch(setLanguage(i18n.language as string));
        }
    }, [queryParams, dispatch, i18n]);

    const { isAuthorized, menuConfig, userLastLocation, user } = useSelector(
        ({ auth, urls, builder: { menuConfig }, data }: RouterState) => ({
            menuConfig,
            isAuthorized: auth.user != null,
            userLastLocation: routerHelpers.getLastLocation(),
            user: auth.user,
        }),
        shallowEqual
    );

    let activeSub = false;
    let expiredSub = false;
    let isParent = false;

    if (userType === "admin") activeSub = true;

    if (user && user.sub && user.sub.id) activeSub = true;
    else if (user && user.parent && user.parent.sub && user.parent.sub.id) activeSub = true;

    if (user && user.expSub && user.expSub.length) expiredSub = true;
    else if (user && user.parent && user.parent.expSub && user.parent.expSub.length) expiredSub = true;

    if (user && user.parent) isParent = true;

    const redirect = activeSub ? false : expiredSub ? false : true;

    return (
        <LayoutContextProvider history={history} menuConfig={menuConfig}>
            <ScrollToTop />
            <Switch>
                {!isAuthorized ? (
                    <AuthPage />
                ) : activeSub && (
                    <Redirect from="/auth" to={user.landingPage ? user.landingPage : userLastLocation} />
                )}
                <Route path="/error" component={ErrorsPage} />
                <Route path="/logout" component={LogoutPage} />

                {!activeSub ? <Route path="/get-started" component={GetStartedPage} /> : ""}

                {redirect ? <Redirect to="/get-started/" /> : ""}

                {expiredSub && !activeSub && !isParent ? <Redirect to="/get-started/" /> : ""}

                {activeSub ? <Redirect from="/get-started/review" exact={true} to="/dashboard" /> : ""}

                {isAuthorized ? (
                    <Layout>
                        <LoggedInRoutes fullView={activeSub} />
                    </Layout>
                ) : (
                    ""
                )}
            </Switch>
        </LayoutContextProvider>
    );
});
