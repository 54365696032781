import React, { useState } from "react";

export const PlatformContext = React.createContext();

const PlatformProvider = ({ children }) => {
    const [adsType, setAdsType] = useState("google");
    const updateAdType = (type) => setAdsType(type);
    return (
        <PlatformContext.Provider
            value={{
                adsType,
                updateAdType,
            }}
        >
            {children}
        </PlatformContext.Provider>
    );
};

export default PlatformProvider;