import axios from "axios";

export function generateMetaOAuth(platform: string) {
  return axios.get("/meta_ads/auth_url", { params: { platform: platform } });
}

export function getTokenByCode(code: string) {
  return axios.post("/users/id/meta_ads/token", { code: code });
}

export function sharePostOnMeta() {
  return axios.post("/users/id/meta_ads/share_post");
}

export function uploadImageOnMeta(file: any, onUploadProgress) {
  return axios.post("/users/id/meta_ads/upload_image", file, onUploadProgress);
}

export function updateActiveAccount(client, status, value, business) {
  return axios.put("/users/id/meta_ads/active_account", {
    client: client,
    status: status,
    value: value,
    businessID: business,
  });
}

export function getAccountsList() {
  return axios.get("/users/id/meta_ads/accounts");
}

export function createNewAccount(input) {
  return axios.post("/users/id/meta_ads/account", input);
}

export function getSearchCampaigns(input) {
  return axios.get("/users/id/meta_ads/search/campaigns", { params: input });
}

export function createSearchCampaign(campaign) {
  return axios.post("/users/id/meta_ads/search/campaigns", campaign);
}

export function changeSearchCampaignStatus(campaigns) {
  return axios.put("/users/id/meta_ads/search/campaigns/status", campaigns);
}

export function updateSearchCampaigns(data: { campaignId: any; name: string }) {
  return axios.put("/users/id/meta_ads/search/campaigns/update", data);
}

export function deleteSearchCampaigns(cid: any) {
  return axios.delete("/users/id/meta_ads/search/campaigns/delete", { data: cid });
}

export function updateSearchCampaignBudget(budget) {
  return axios.put("/users/id/meta_ads/search/campaign_budget", budget);
}

export function getSearchAdGroups(input) {
  return axios.get("/users/id/meta_ads/search/ad_groups", { params: input });
}

export function createSearchAdGroup(adGroup) {
  return axios.post("/users/id/meta_ads/search/ad_groups", adGroup);
}

export function changeSearchAdGroupStatus(data: { adGroupsId: any; status: string }) {
  return axios.put("/users/id/meta_ads/search/ad_groups/status", data);
}

export function updateSearchAdGroup(data: { adGroupsId: any; name: string; dailyBudget: any }) {
  return axios.put("/users/id/meta_ads/search/ad_groups/update", data);
}

export function deleteSearchAdGroups(resources) {
  return axios.post("/users/id/meta_ads/search/ad_groups/delete", resources);
}

export function getSearchAdGroupAds(input) {
  return axios.get("/users/id/meta_ads/search/ad_group_ads", { params: input });
}

export function createSearchAdGroupAd(adgroupAd) {
  return axios.post("/users/id/meta_ads/search/ad_group_ads", adgroupAd);
}

export function changeSearchAdGroupAdsStatus(data: { adGroupAdsId: any; status: string }) {
  return axios.put("/users/id/meta_ads/search/ad_group_ads/status", data);
}

export function updateSearchAdGroupAds(data: { title: any; text: any; targetUrl: any; adGroupAdsId: any }) {
  return axios.put("/users/id/meta_ads/search/ad_group_ads/update", data);
}

export function deleteSearchAdGroupAds(resources) {
  return axios.post("/users/id/meta_ads/search/ad_group_ads/delete", resources);
}

export function postMetaAdWizard(input: any) {
  return axios.post("/users/id/meta_ads/meta_ad_wizard", input);
}
export function postInstaAdWizard(input: any) {
  return axios.post("/users/id/meta_ads/insta_ad_wizard", input);
}

export function getBusinessList(input: any) {
  return axios.post("/users/id/meta_ads/businesses", input);
}

export function activeInstagram() {
  return axios.post("/users/id/activeInstagram",);
}

export function removeMetaAccount(input: any) {
  return axios.post("/users/id/meta_ads/removeAccount", input);
}

export function switchMetaAccount(account: any) {
  return axios.put("/users/id/meta_ads/switch_account", { account: account });
}