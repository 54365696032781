import axios from "axios";

export function generateLinkedinOAuth() {
  return axios.get("/twitter_ads/auth_url");
}

export function getTokenByCode(code: string, values: Object) {
  return axios.post("/users/id/twitter_ads/token", { code: code, values: values });
}

export function sharePostOnMeta() {
  return axios.post("/users/id/twitter_ads/share_post");
}

export function uploadImageOnTwitter(file: any, onUploadProgress) {
  return axios.post("/users/id/twitter_ads/upload_image", file, onUploadProgress);
}
export function uploadVideoOnTwitter(body) {
  return axios.post("/users/id/twitter_ads/upload_video", body);
}

export function updateActiveAccount(customer, client, status) {
  return axios.put("/users/id/twitter_ads/active_account", { customer: customer, client: client, status: status });
}

export function getAccountsList() {
  return axios.get("/users/id/twitter_ads/accounts");
}

export function createNewAccount(input) {
  return axios.post("/users/id/twitter_ads/account", input);
}

export function getSearchCampaigns(input) {
  return axios.get("/users/id/twitter_ads/search/campaigns", { params: input });
}

export function createSearchCampaign(campaign) {
  return axios.post("/users/id/twitter_ads/search/campaigns", campaign);
}

export function changeSearchCampaignStatus(campaigns) {
  return axios.put("/users/id/twitter_ads/search/campaigns/status", campaigns);
}

export function updateSearchCampaign(data: { name: string; campaignId: any }) {
  return axios.put("/users/id/twitter_ads/search/campaigns/update", data);
}

export function deleteSearchCampaigns(cid: any) {
  return axios.delete("/users/id/twitter_ads/search/campaigns/delete", { data: cid });
}

export function updateSearchCampaignBudget(budget) {
  return axios.put("/users/id/twitter_ads/search/campaign_budget", budget);
}

export function getSearchAdGroups(input) {
  return axios.get("/users/id/twitter_ads/search/ad_groups", { params: input });
}

export function createSearchAdGroup(adGroup) {
  return axios.post("/users/id/twitter_ads/search/ad_groups", adGroup);
}

export function changeSearchAdGroupStatus(data: { adGroupId: any; status: string }) {
  return axios.put("/users/id/twitter_ads/search/ad_groups/status", data);
}

export function updateSearchAdGroup(data: { adGroupId: any; name: string; dailyBudget: any }) {
  return axios.put("/users/id/twitter_ads/search/ad_groups/update", data);
}

export function deleteSearchAdGroups(resources) {
  return axios.post("/users/id/twitter_ads/search/ad_groups/delete", resources);
}

export function getSearchAdGroupAds(input) {
  return axios.get("/users/id/twitter_ads/search/ad_group_ads", { params: input });
}

export function createSearchAdGroupAd(adgroupAd) {
  return axios.post("/users/id/twitter_ads/search/ad_group_ads", adgroupAd);
}

export function changeSearchAdGroupAdsStatus(data: { adGroupAdsId: any; status: string }) {
  return axios.put("/users/id/twitter_ads/search/ad_group_ads/status", data);
}

export function updateSearchAdGroupAds(data: { adGroupAdsId: any; name: string; targetUrl: any }) {
  return axios.put("/users/id/twitter_ads/search/ad_group_ads/update", data);
}

export function deleteSearchAdGroupAds(resources) {
  return axios.post("/users/id/twitter_ads/search/ad_group_ads/delete", resources);
}

export function postTwitterAdWizard(input: any) {
  return axios.post("/users/id/twitter_ads/twitter_ad_wizard", input);
}

export function getBusinessList() {
  return axios.get("/users/id/twitter_ads/businesses");
}

export function removeTwitterAccount(input: any) {
  return axios.post("/users/id/twitter_ads/remove_account", input);
}

export function switchTwitterAccount(account: any) {
  return axios.put("/users/id/twitter_ads/switch_account", { account: account });
}