import React, { useState } from "react";

export const LoginAsContext = React.createContext();

const LoginAsProvider = (props) => {
    const [userType, setUserType] = useState("subscriber");
    return <LoginAsContext.Provider value={{ userType, setUserType }}>{props.children}</LoginAsContext.Provider>;
};

export default LoginAsProvider;
