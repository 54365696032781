import React, { useContext } from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import { useLocation } from "react-router-dom";
import Header from "./header/Header";
import SubHeader from "./sub-header/SubHeader";
import HeaderMobile from "./header/HeaderMobile";
import AsideLeft from "./aside/AsideLeft";
import Footer from "./footer/Footer";
import ScrollTop from "../../app/partials/layout/ScrollTop";
// import AnimateLoading from "../../app/partials/layout/AnimateLoading";
import SharedMessage from "../../app/partials/layout/SharedMessage";
import HTMLClassService from "./HTMLClassService";
import LayoutConfig from "./LayoutConfig";
import MenuConfig from "./MenuConfig";
import LayoutInitializer from "./LayoutInitializer";
import KtContent from "./KtContent";
import "./assets/Base.scss";
import { LoginAsContext } from "src/app/context/LoginContext";

const htmlClassService = new HTMLClassService();

function Layout({ children, asideDisplay, subheaderDisplay, selfLayout, layoutConfig, user, contentContainerClasses }) {
    htmlClassService.setConfig(layoutConfig);
    // scroll to top after location changes
    // window.scrollTo(0, 0);

    const contentCssClasses = htmlClassService.classes.content.join(" ");

    const { userType } = useContext(LoginAsContext);

    let activeSub = false;
    let expiredSub = false;

    if (user && user.sub && user.sub.id) activeSub = true;
    else if (user && user.parent && user.parent.sub && user.parent.sub.id) activeSub = true;

    if (user && user.expSub && user.expSub.length) expiredSub = true;
    else if (user && user.parent && user.parent.expSub && user.parent.expSub.length) expiredSub = true;

    const location = useLocation();

    let partialViewClass = "";

    if (location.pathname === "/dashboard") {
        partialViewClass = activeSub === true ? "" : expiredSub === true && userType !== "admin" ? "partial-view" : "";
    }

    partialViewClass += activeSub === false && userType !== "admin" ? " get-started-view" : "";

    return selfLayout !== "blank" ? (
        <LayoutInitializer menuConfig={MenuConfig} layoutConfig={LayoutConfig} htmlClassService={htmlClassService}>
            {/* <!-- begin:: Header Mobile --> */}
            <HeaderMobile />
            {/* <!-- end:: Header Mobile --> */}

            <div className={`kt-grid kt-grid--hor kt-grid--root ${partialViewClass}`}>
                {/* <!-- begin::Body --> */}
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                    {/* <!-- begin:: Aside Left --> */}
                    {asideDisplay && (
                        <>
                            <AsideLeft />
                        </>
                    )}
                    {/* <!-- end:: Aside Left --> */}
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                        {/* <!-- begin:: Header READY --> */}

                        <Header />
                        {/* <!-- end:: Header --> */}

                        {/* <!-- begin:: Content --> */}
                        <div
                            id="kt_content"
                            className={`kt-content ${contentCssClasses} kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
                        >
                            {/* <!-- begin:: Content Head --> */}
                            {subheaderDisplay && <SubHeader />}
                            {/* <!-- end:: Content Head --> */}

                            {/* <!-- begin:: Content Body --> */}
                            {/* TODO: add class to animate  kt-grid--animateContent-finished */}
                            <KtContent>
                                {/* <AnimateLoading /> */}
                                <SharedMessage />
                                {children}
                            </KtContent>
                            {/*<!-- end:: Content Body -->*/}
                        </div>
                        {/* <!-- end:: Content --> */}
                        <Footer />
                    </div>
                </div>
                {/* <!-- end:: Body --> */}
                {userType === "admin" && (
                    <div className="login-as-bar alert alert-danger show">
                        You are logged in as&nbsp;<strong>{user.username}</strong>
                    </div>
                )}
            </div>
            <ScrollTop />
        </LayoutInitializer>
    ) : (
        // BLANK LAYOUT
        <div className="kt-grid kt-grid--ver kt-grid--root">
            <KtContent>{children}</KtContent>
        </div>
    );
}

const mapStateToProps = ({ builder: { layoutConfig }, auth }) => ({
    layoutConfig,
    selfLayout: objectPath.get(layoutConfig, "self.layout"),
    asideDisplay: objectPath.get(layoutConfig, "aside.self.display"),
    subheaderDisplay: objectPath.get(layoutConfig, "subheader.display"),
    desktopHeaderDisplay: objectPath.get(layoutConfig, "header.self.fixed.desktop"),
    user: auth.user,
    contentContainerClasses: "",
    // contentContainerClasses: builder.selectors.getClasses(store, {
    //   path: "content_container",
    //   toString: true
    // })
});

export default connect(mapStateToProps)(Layout);
