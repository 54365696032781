import { ADD_ACTIVITY, IS_FETCH_ACTIVIES, SET_ACTIVITIES, SET_LIMIT, SET_NEXT_ACTIVITIES, RESET_ACTIVITY } from "./activity.types";

export interface IActivites {
  data: any[];
  isLoading: boolean;
  limit: number;
}

export const InitialState: IActivites = {
  data: [],
  isLoading: false,
  limit: 5
};

const activitiesReducer = (state = InitialState, action) => {
  switch (action.type) {
    case SET_ACTIVITIES:
      return {
        ...state,
        data: action.payload
      };
    case SET_NEXT_ACTIVITIES: 
      return {
        ...state,
        data: [...state.data, ...action.payload]
      };
    case SET_LIMIT:
      return {
        ...state,
        limit: action.payload
      };
    case ADD_ACTIVITY: 
      const updatedData = [...state.data];
      updatedData.splice(0,0, action.payload);
      return {
        ...state,
        data: [...updatedData]
      }
    case IS_FETCH_ACTIVIES:
        return {
          ...state,
          isLoading: action.payload
        };
    case RESET_ACTIVITY:
        return InitialState;
    default:
      return state;
  }
};

export default activitiesReducer;
