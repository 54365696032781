import axios from "axios";

export function generateOAuthUrl() {
    return axios.get("google_merchant/auth_url");
}

export function getAccountsList() {
    return axios.get("/users/id/google_merchant/accounts");
}

export function getAccountsListByToken(code: string) {
    return axios.post("/users/id/google_merchant/token", { code: code });
}

export function createNewAccount() {
    return axios.post("/users/id/google_merchant/account", {});
}

export function updateActiveAccount(merchant) {
    return axios.put("/users/id/google_merchant/active_account", { merchant: merchant });
}

export function removeAccount(accountIds) {
    return axios.post("/users/id/google_merchant/remove_account", { accountIds: accountIds });
}

export function deleteManagedAccount() {
    return axios.delete("/users/id/google_merchant/account", {});
}

export function getProducts(pagination) {
    return axios.post("/users/id/google_merchant/content/products", { pagination: pagination });
}

export function getSingleProduct(id) {
    return axios.get(`/users/id/google_merchant/content/products/${id}`, {});
}

export function updateSingleProduct(product) {
    return axios.put(`/users/id/google_merchant/content/products`, { product: product });
}

export function deleteProducts(ids) {
    return axios.post(`/users/id/google_merchant/content/products/delete`, { products: ids });
}

export function createProductData(data) {
    return axios.post("/data/product", data);
}

export function getFeeds() {
    return axios.get("/users/id/google_merchant/content/feeds", {});
}

export function createFeed(params) {
    return axios.post("/users/id/google_merchant/content/feeds", params);
}

export function updateFeed(feeds) {
    return axios.put(`/users/id/google_merchant/content/feeds`, feeds);
}

export function deleteFeeds(ids) {
    return axios.post(`/users/id/google_merchant/content/feeds/delete`, { feeds: ids });
}

export function removeGoogleMerchantAccount(payload) {
    return axios.post("/google_merchant/removeAccount", payload);
}