import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { RootState } from "../../store";
import { setLanguage } from "../../store/data";
import i18n from '../../../i18n';
import { ActionModalActivity } from "src/app/utils/activityLog";

export const LanguageContext = React.createContext<any>({});

type Language = {
  lang: string
  name: string
  flag: string
}

const languages: Array<Language> = [
  {
    lang: "en",
    name: "English",
    flag: toAbsoluteUrl("/media/flags/226-united-states.svg")
  },
  {
    lang: "de",
    name: "German",
    flag: toAbsoluteUrl("/media/flags/162-germany.svg")
  }
];

type LanguageSelectorProps = {
  lang: string
  iconType: string
  setLanguage: Function
  location?: any
}

class LanguageSelector extends React.Component<LanguageSelectorProps> {

  reInitDatePicker = () => {
    //console.log(this.DRContext);
  }

  DRContext = LanguageContext;

  render() {
    const { lang, iconType, setLanguage } = this.props;
    const selectedLanguage = languages.find(x => x.lang === lang);
    const currentLanguage = selectedLanguage !== undefined ? selectedLanguage : {
      lang: "en",
      name: "English",
      flag: toAbsoluteUrl("/media/flags/226-united-states.svg")
    };

    return (
      <Dropdown
        className="kt-header__topbar-item kt-header__topbar-item--langs"
        drop="down" alignRight
      >
        <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-my-cart">
          <span
            className={clsx("kt-header__topbar-icon", {
              "kt-header__topbar-icon--brand": iconType === "brand"
            })}
          >
            <img src={currentLanguage.flag} alt={currentLanguage.name} />
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
          <ul className="kt-nav kt-margin-t-10 kt-margin-b-10">
            {languages.map(language => (
              <li
                key={language.lang}
                className={clsx("kt-nav__item", {
                  "kt-nav__item--active": language.lang === currentLanguage.lang
                })}
              >
                <span
                  onClick={() => {
                    ActionModalActivity("partials.language.title");
                    i18n.changeLanguage(language.lang);
                    setLanguage(language.lang);
                    this.setState({ open: false });
                    this.reInitDatePicker();
                  }}
                  className={clsx("kt-nav__link", {
                    "kt-nav__link--active":
                      language.lang === currentLanguage.lang
                  })}
                >
                  <span className="kt-nav__link-icon">
                    <img src={language.flag} alt={language.name} />
                  </span>
                  <span className="kt-nav__link-text">{language.name}</span>
                </span>
              </li>
            ))}
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = ({ data }: RootState) => ({ lang: data.locale });

export default connect(
  mapStateToProps,
  { setLanguage }
)(LanguageSelector);
