import { GET_TEMPLATES, RESET_TICKET, GET_CREATE_TICKET_TEMPLATES } from "./ticket.types";

export interface iInitialState {
    isLoading: boolean;
    templates: any[];
    isCreateTicketTemplates: any[];
}

export const InitialState: iInitialState = {
    isLoading: false,
    templates: [],
    isCreateTicketTemplates: [],
};

const ticketReducer = (state: iInitialState = InitialState, action) => {
    switch (action.type) {
        case GET_TEMPLATES:
            return { ...state, templates: action.payload };
        case GET_CREATE_TICKET_TEMPLATES:
            return { ...state, isCreateTicketTemplates: action.payload };
        case RESET_TICKET:
            return InitialState;
        default:
            return state;
    }
};

export default ticketReducer;
