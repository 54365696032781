import {
    FETCH_PLATFORMS,
    RESET_PLATFORM,
    SET_ACCOUNT,
    GET_TEMPLATES,
    VALIDATE_TOKEN,
    SET_ALL_ACCOUNTS,
} from "./platform.types";

export interface iInitialState {
    isLoading: boolean;
    list: {
        connected: any;
        valid: any;
    };
    account: any[];
    allAccounts: any[];
    templates: any[];
}

export const InitialState: iInitialState = {
    isLoading: false,
    list: { connected: null, valid: null },
    account: [],
    allAccounts: [],
    templates: [],
};

const platformReducer = (state: iInitialState = InitialState, action) => {
    switch (action.type) {
        case FETCH_PLATFORMS:
            return { ...state, isLoading: action.payload };
        case VALIDATE_TOKEN:
            return { ...state, list: action.payload };
        case SET_ACCOUNT:
            return { ...state, account: action.payload };
        case SET_ALL_ACCOUNTS:
            return { ...state, allAccounts: action.payload };
        case GET_TEMPLATES:
            return { ...state, templates: action.payload };
        case RESET_PLATFORM:
            return InitialState;
        default:
            return state;
    }
};

export default platformReducer;
