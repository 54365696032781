import { getCompetitorUrlData, getCrawlUrlData, getMediaData } from "src/app/crud/data.crud";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const getCompetitors = createAsyncThunk("FETCH_COMPETITORS", async ({ idx }: { idx: number }) => {
    const response = await getCompetitorUrlData({ adsAccountIdx: idx });
    return response.data.competitors;
});

export const getMedia = createAsyncThunk("FETCH_MEDIA", async ({ idx }: { idx: number }) => {
    const response = await getMediaData({ adsAccountIdx: idx });
    return response.data.media;
});

export const getOwnResources = createAsyncThunk("FETCH_OWN_RESOURCES", async ({ idx }: { idx: number }) => {
    const response = await getCrawlUrlData({ adsAccountIdx: idx });
    return response.data.pages;
});
