export enum PLATFORMS {
    GOOGLE_ADS = "googleAds",
    GOOGLE_MERCHANT = "googleMerchant",
    GOOGLE_ANALYTICS = "googleAnalytics",
    FACEBOOK = "facebook",
    INSTAGRAM = "instagram",
    TWITTER = "twitter",
    LINKEDIN = "linkedin",
    TABOOLA = "taboola",
    TIKTOK = "tiktok",
}

export enum PRODUCTS {
    GOOGLE_ADS_ACCOUNT = "Google Ads Account",
}
