import { Reducer } from 'redux'
import { persistReducer } from "redux-persist";
import { PersistPartial } from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import * as routerHelpers from "../router/RouterHelpers";
import { takeLatest, put, call } from 'redux-saga/effects';
import { action } from 'typesafe-actions';
import { getMe, logout as logoutFromServer } from '../crud/auth.crud';


export enum AuthActionTypes {
    LOGIN = "auth/login",
    LOGOUT = "auth/logout",
    REGISTER = "auth/register",
    UPDATE = "auth/update",
    GET = "auth/get"
}

export type AuthState = {
    readonly user: any
}

export type User = {
    id?: string | number
    firstName?: string
    lastName?: string
    email?: string
    password?: string
    website?: string
    phoneNumber?: string
    company?: string
    address?: {
        street?: string
        houseNumber?: string
        additional?: string
        postCode?: string
        city?: string
        country?: string
    }
    createdAt?: string
    state?: string
    role?: string
    avatar?: string,
    profilePic?: string
}


export const login = (user: User) => action(AuthActionTypes.LOGIN, { user });
export const logout = () => action(AuthActionTypes.LOGOUT);
export const register = (user: User) => action(AuthActionTypes.REGISTER, { user });
export const updateMe = (user: User) => action(AuthActionTypes.UPDATE, { user });
export const get = () => action(AuthActionTypes.GET);



/*
    Whenever Logout action is fired, it destorys the session on server.
*/

function* saga() {
    yield takeLatest(AuthActionTypes.LOGOUT, function* logoutSaga() {
        yield logoutFromServer();
    });
}

/*
    Whenever fetch data action is fired
*/

function* watchFetchData() {
    yield takeLatest(AuthActionTypes.GET, function* fetchData() {
        const response = yield call(getMe);
        yield put({ type: AuthActionTypes.UPDATE, payload: { user: response.data } })
    })
}

export { saga as authSaga };
export { watchFetchData as fetchDataSaga }

const initialState: AuthState = {
    user: undefined
}

const reducer: Reducer<AuthState & PersistPartial> = persistReducer(
    { storage, key: "auth", whitelist: ["user", "authToken"] },
    (state = initialState, action) => {
        switch (action.type) {
            case AuthActionTypes.LOGIN: {
                const { user } = action.payload;
                return { user, authToken: undefined };
            }
            case AuthActionTypes.REGISTER: {
                const { user } = action.payload;
                return { authToken: undefined, user };
            }
            case AuthActionTypes.LOGOUT: {
                routerHelpers.forgotLastLocation();
                return initialState;
            }

            case AuthActionTypes.GET: {
                return state;
            }

            case AuthActionTypes.UPDATE: {
                const { user } = action.payload;
                return { authToken: undefined, user };
            }
            default: {
                return state
            }
        }
    });

export { reducer as authReducer }
