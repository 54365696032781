import i18n from 'i18next'
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import moment from 'moment';
import 'moment/locale/de';

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
    fallbackLng: "en",
    debug: false,
    keySeparator: ".",
    interpolation: {
        escapeValue: false,
        formatSeparator: ",",
        format: function (value, formatting, lng) {
            if (value instanceof Date) {
                moment.locale(lng);
                return moment(value).format(formatting);
            };
            return value;
        }
    },
    backend: {
        loadPath: (lang, ns) => { const locale = lang[0].substring(0, 2); return `/locales/${locale.toLowerCase()}.json` },
    },
    react:{
     //   wait: true,
        useSuspense : true,
        nsMode: 'default',
       // bindI18n: 'languageChanged loaded data/locale'
       // bindStore: 'added removed',
    }
});

export default i18n;