import axios from "axios";

export function generateOAuthUrl() {
    return axios.get("google_analytics/auth_url");
}

export function getAccountsList() {
    return axios.get("/users/id/google_analytics/accounts");
}

export function getAccountsListByToken(code: string) {
    return axios.post("/users/id/google_analytics/token", { code: code });
}

export function updateActiveAccount(analytics) {
    return axios.put("/users/id/google_analytics/active_account", { analytics: analytics });
}

export function createNewAccount() {
    return axios.post("/users/id/google_analytics/account", {});
}

export function removeAccount() {
    return axios.post("/users/id/google_analytics/removeAccount", {});
}