/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import objectPath from "object-path";
import KTToggle from "../../_assets/js/toggle";
import * as builder from "../../ducks/builder";
import { ReactComponent as AngleDoubleLeftIcon } from "../assets/layout-svg-icons/Angle-double-left.svg";
import { ReactComponent as AngleDoubleRightIcon } from "../assets/layout-svg-icons/Angle-double-right.svg";
import { actions } from "../../ducks/builder";
import { TourContext } from "src/app/context/TourContext";

class Brand extends React.Component {
  static contextType = TourContext;
  ktToggleRef = React.createRef();

  togglefunction() {
    const handler = new KTToggle(this.ktToggleRef.current, this.props.toggleOptions);
    handler.on("toggle", () => {
      objectPath.set(
        this.props.layoutConfig,
        "aside.self.minimize.default",
        handler.getState() === "on" ? true : false
      );
      this.props.dispatch(actions.setLayoutConfigs(this.props.layoutConfig));

    });
  }

  componentDidMount() {
    this.togglefunction();
  }

  render() {
    const { setStepIndexState } = this.context;
    return (
      <>
        <div
          className={`kt-aside__brand kt-grid__item ${this.props.brandClasses}`}
          id="kt_aside_brand"
          onClick={() => {
            setStepIndexState((prev) => prev + 1);
          }}
        >
          <div className="kt-aside__brand-logo">
            <Link to="">
              <img alt="logo" src={this.props.headerLogo} />
            </Link>
          </div>

          {this.props.asideSelfMinimizeToggle && (
            <div className="kt-aside__brand-tools">
              <button
                ref={this.ktToggleRef}
                className="kt-aside__brand-aside-toggler"
                id="kt_aside_toggler"
              >
                <span>
                  <AngleDoubleLeftIcon />
                </span>
                <span>
                  <AngleDoubleRightIcon />
                </span>
              </button>
            </div>
          )}
        </div>
      </>

    );
  }
}

const mapStateToProps = store => {
  return {
    brandClasses: builder.selectors.getClasses(store, {
      path: "brand",
      toString: true
    }),
    asideSelfMinimizeToggle: objectPath.get(
      store.builder.layoutConfig,
      "aside.self.minimize.toggle"
    ),
    layoutConfig: store.builder.layoutConfig,
    headerLogo: builder.selectors.getLogo(store),
    headerStickyLogo: builder.selectors.getStickyLogo(store),
    toggleOptions: {
      target: "body",
      targetState: "kt-aside--minimize",
      togglerState: "kt-aside__brand-aside-toggler--active"
    }
  };
};

export default connect(mapStateToProps)(Brand);
