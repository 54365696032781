import { getAccount, getTemplate } from "src/app/crud/postWizard.crud";
import {
    FETCH_PLATFORMS,
    PLATFORM_LIST,
    SET_ACCOUNT,
    GET_TEMPLATES,
    VALIDATE_TOKEN,
    SET_ALL_ACCOUNTS,
} from "./platform.types";
import axios from "axios";
import * as googleAds from "src/app/crud/googleAds.crud";
import * as googleMerchant from "src/app/crud/googleMerchant.crud";
import * as googleAnalytics from "src/app/crud/googleAnalytics.crud";
import { PLATFORMS } from "src/types/enums";

// fetch functions

export const fetchValidateToken = () => {
    return async function(dispatch) {
        try {
            dispatch(setIsLoading(true));
            const responce = await axios.get("token/validate");
            dispatch(setValidateToken(responce.data));
            dispatch(setIsLoading(false));
        } catch (err) {
            dispatch(setIsLoading(false));
        }
    };
};

export const fetchAccount = () => {
    return async function(dispatch) {
        const details: { platform: string; account: any }[] = [];
        try {
            dispatch(setIsLoading(true));
            await Promise.all(
                PLATFORM_LIST.map(async (platform) => {
                    const response = await getAccount(platform);
                    details.push({ platform: platform, account: response.data });
                })
            );
            dispatch(setAccount(details));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setAccount(details));
            dispatch(setIsLoading(false));
            console.error("fetchAccount", error);
        }
    };
};

export const fetchAllAccounts = () => {
    return async function(dispatch) {
        const details: { platform: string; account: any }[] = [];
        try {
            dispatch(setIsLoading(true));
            const list = PLATFORM_LIST.filter((p) => p.includes("google"));
            await Promise.all(
                list.map(async (platform) => {
                    let response;
                    switch (platform) {
                        case PLATFORMS.GOOGLE_ADS:
                            response = await googleAds.getAccountsList();
                            break;
                        case PLATFORMS.GOOGLE_MERCHANT:
                            response = await googleMerchant.getAccountsList();
                            break;
                        case PLATFORMS.GOOGLE_ANALYTICS:
                            response = await googleAnalytics.getAccountsList();
                            break;
                        default:
                            break;
                    }
                    details.push({ platform: platform, account: response?.data });
                })
            );
            dispatch(setAllAccount(details));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setAllAccount(details));
            dispatch(setIsLoading(false));
            console.error("fetchAllAccounts", error);
        }
    };
};

export const getTemplates = () => {
    return async function(dispatch) {
        try {
            const data: { hashtag: Array<string>; name: string; text: string; _id: string }[] = [];
            const response = await getTemplate();
            const responseData = response.data.template;
            await Promise.all(
                responseData.map(async (item) => {
                    data.push({ hashtag: item.hashtag, name: item.name, text: item.text, _id: item._id });
                })
            );
            dispatch(setTemplates(data));
        } catch (error) {
            console.log(error);
        }
    };
};

// set functions

export const setIsLoading = (value) => {
    return {
        type: FETCH_PLATFORMS,
        payload: value,
    };
};

export const setValidateToken = (details) => {
    return {
        type: VALIDATE_TOKEN,
        payload: details,
    };
};

export const setAccount = (details) => {
    return {
        type: SET_ACCOUNT,
        payload: details,
    };
};

export const setAllAccount = (details) => {
    return {
        type: SET_ALL_ACCOUNTS,
        payload: details,
    };
};

export const setTemplates = (data) => {
    return {
        type: GET_TEMPLATES,
        payload: data,
    };
};
