import { createSlice } from "@reduxjs/toolkit";

import { getCompetitors, getMedia, getOwnResources } from "./activeAccountAction";

const initialState = {
    idx: 0,
    qualification: null,
    ownResources: [],
    ownResourcesInProgress: false,
    media: [],
    mediaInProgress: false,
    competitors: [],
    competitorsInProgress: false,
};

const activeAccountSlice = createSlice({
    name: "activeAccount",
    initialState: initialState,
    reducers: {
        switchAccount: (state, action) => {
            state.idx = action.payload.idx;
        },
        setQualification: (state, action) => {
            state.qualification = action.payload.qualification;
        },
        ownResourcesInProgress: (state, action) => {
            state.ownResourcesInProgress = action.payload.inProgress;
        },
        competitorsInProgress: (state, action) => {
            state.competitorsInProgress = action.payload.inProgress;
        },
        mediaInProgress: (state, action) => {
            state.mediaInProgress = action.payload.inProgress;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getOwnResources.fulfilled, (state, action) => {
            state.ownResources = action.payload;
            state.ownResourcesInProgress = false;
        });
        builder.addCase(getCompetitors.fulfilled, (state, action) => {
            state.competitors = action.payload;
            state.competitorsInProgress = false;
        });
        builder.addCase(getMedia.fulfilled, (state, action) => {
            state.media = action.payload;
            state.mediaInProgress = false;
        });
    },
});

export const {
    ownResourcesInProgress,
    competitorsInProgress,
    mediaInProgress,
    setQualification,
    switchAccount,
} = activeAccountSlice.actions;

export default activeAccountSlice.reducer;
