import axios from "axios";
import { IActivityView } from "src/types/Types";

export function postActivity(activity: IActivityView) {
    return axios.post("/users/id/activity", activity);
}

export function getActivity(limit: number) {
    return axios.get(`/users/id/activity/${limit}`);
}
