/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Button, Row, Col, Modal, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from 'yup';
import { createMember, updateMember } from "../../crud/members.crud";
import { AddActivity, UpdateActivity } from "src/app/utils/activityLog";

export default function NewMember({ show, onSubmit, onClose, editData }) {

    const { t } = useTranslation();
    const [lastStatus, setLastStatus] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const history = useHistory();

    const FormSchema = Yup.object().shape({
        first_name: Yup.string()
            .required(t('general.required')),
        last_name: Yup.string()
            .required(t('general.required')),
        email: Yup.string()
            .required(t('general.required')),
        username: Yup.string()
            .required(t('general.required')),
        // status: Yup.string()
        //     .required(t('general.required')),
        // type: Yup.string()
        //     .required(t('general.required')),
    });

    const Form = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            username: "",
            // status: "1",
            // type: "2",
        },
        validationSchema: FormSchema,
        onSubmit: async values => {
            try {
                if (!editData) {
                    await createMember(undefined, values.first_name, values.last_name, values.email, values.username);
                    AddActivity("topbar.sub.newMember");
                    AddActivity("member.subheaderTitle");
                }
                else {
                    await updateMember(editData.id, values.first_name, values.last_name, values.email, values.username, lastStatus);
                    UpdateActivity("topbar.sub.newMember");
                }
                Form.resetForm();
                history.push('/user/members');
                onSubmit(true);
            }
            catch (e) {
                if (e.response.data && typeof e.response.data === "string") {
                    if (e.response.data === "userEmailExist") {
                        setErrorMsg(t('member.emailExistMember'));
                    }
                    else if (e.response.data === "userNameExist") {
                        setErrorMsg(t('member.userNameExistMember'));
                    }
                    else {
                        setErrorMsg(t('general.error'));
                    }
                }
                else {
                    setErrorMsg(t('general.error'));
                }
            }
        },
    });

    const editFormFields = () => {
        if (editData) {
            Form.setFieldValue('first_name', editData.first_name);
            Form.setFieldValue('last_name', editData.last_name);
            Form.setFieldValue('email', editData.user_email);
            Form.setFieldValue('username', editData.user_login);
            // Form.setFieldValue('status', editData.status ? editData.status : 2);
            // Form.setFieldValue('type', editData.type ? editData.type : 1);
            setLastStatus(editData.status);
        }
    }

    const onHideModal = () => {
        editData = {};
        Form.resetForm();
        setErrorMsg("");
        onClose(true);
    }

    return (
        <>
            <Modal size="lg" show={show} onHide={onHideModal} onShow={editFormFields}>
                <Modal.Header closeButton>
                    <Modal.Title>{editData ? t('member.editMember') : t('member.newMember')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert show={successMsg ? true : false} variant="success" onClose={() => setSuccessMsg("")} dismissible>
                        {successMsg}
                    </Alert>

                    <Alert show={errorMsg ? true : false} variant="danger" onClose={() => setErrorMsg("")} dismissible>
                        {errorMsg}
                    </Alert>
                    <form action="#" className="form form-label-right">
                        <Row className="form-group">
                            <Col lg={6}>

                                <input type="text" className="form-control" name="first_name" placeholder={t('member.firstName')} onBlur={Form.handleBlur} onChange={Form.handleChange} value={Form.values.first_name} />

                                {/* {!Form.touched.first_name || !Form.errors.first_name ? <div className="feedback">Please enter <b>{t('member.firstName')}</b></div> : ""} */}

                                {Form.touched.first_name && Form.errors.first_name ?
                                    <div className="validated">
                                        <div className="invalid-feedback">{Form.errors.first_name}</div>
                                    </div>
                                    :
                                    ""
                                }

                            </Col>
                            <Col lg={6}>
                                <input type="text" className="form-control" name="last_name" placeholder={t('member.lastName')} onBlur={Form.handleBlur} onChange={Form.handleChange} value={Form.values.last_name} />

                                {Form.touched.last_name && Form.errors.last_name ?
                                    <div className="validated">
                                        <div className="invalid-feedback">{Form.errors.last_name}</div>
                                    </div>
                                    :
                                    ""
                                }
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Col lg={6}>
                                <input type="email" className="form-control" name="email" placeholder={t('member.email')} onBlur={Form.handleBlur} onChange={Form.handleChange} value={Form.values.email} />

                                {Form.touched.email && Form.errors.email ?
                                    <div className="validated">
                                        <div className="invalid-feedback">{Form.errors.email}</div>
                                    </div>
                                    :
                                    ""
                                }

                            </Col>
                            <Col lg={6}>
                                <input type="text" className="form-control" name="username" placeholder={t('member.login')} onBlur={Form.handleBlur} onChange={Form.handleChange} value={Form.values.username} />

                                {Form.touched.username && Form.errors.username ?
                                    <div className="validated">
                                        <div className="invalid-feedback">{Form.errors.username}</div>
                                    </div>
                                    :
                                    ""
                                }

                            </Col>
                        </Row>

                        {/* <Row className="form-group"> */}
                        {/* <Col lg={6}>
                                <select className="form-control form-control-solid" name="status" onBlur={Form.handleBlur} onChange={Form.handleChange} value={Form.values.status}>
                                    <option value="1">{t('member.statusDrop.active')}</option>
                                    <option value="2">{t('member.statusDrop.inactive')}</option>
                                </select>

                                {!Form.touched.status || !Form.errors.status ? <div className="feedback">{t('member.labelMessagePrefix')} <b>{t('member.status')}</b> {t('member.labelMessagePostfix')}</div> : ""}

                                {Form.touched.status && Form.errors.status ?
                                    <div className="validated">
                                        <div className="invalid-feedback">{Form.errors.status}</div>
                                    </div>
                                    :
                                    ""
                                }

                            </Col> */}
                        {/* <Col lg={6}> */}
                        {/* <label>Select Type</label> */}
                        {/* <select className="form-control form-control-solid" name="type" onBlur={Form.handleBlur} onChange={Form.handleChange} value={Form.values.type}> */}
                        {/* <option value="1">{t('member.typeDrop.all')}</option> */}
                        {/* <option value="2">{t('member.typeDrop.google')}</option> */}
                        {/* <option value="1">{t('member.typeDrop.invoice')}</option> */}
                        {/* </select> */}

                        {/* {!Form.touched.first_name || !Form.errors.first_name ? <div className="feedback">{t('member.labelMessagePrefix')} <b>{t('member.type')}</b> {t('member.labelMessagePostfix')}</div> : ""} */}

                        {/* {Form.touched.type && Form.errors.type ? */}
                        {/* <div className="validated"> */}
                        {/* <div className="invalid-feedback">{Form.errors.type}</div> */}
                        {/* </div> */}
                        {/* : */}
                        {/* "" */}
                        {/* } */}

                        {/* </Col> */}
                        {/* </Row> */}
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHideModal} color="primary" className="" style={{background: '#f2f3f7', color: '#959cb6', border: '0', fontWeight: 500}}>
                        {t('member.cancel')}
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        className={`btn btn-label-brand btn-bold bg-blue-gradient ${Form.isSubmitting ? "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--brand custom-loading-icon" : ""}`}
                        onClick={Form.submitForm}
                        disabled={Form.isSubmitting}
                    >
                        {t('member.submit')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}