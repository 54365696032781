/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from "react";

export const AdSpaceContext = React.createContext();

const AdSpaceProvider = ({ children }) => {
    const [videoUrls, setVideoUrls] = useState([]);
    const [imageUrls, setImageUrls] = useState([]);
    const [adspaceText, setAdspaceText] = useState("");
    const [showAdSpaceBanner, setShowAdSpaceBanner] = useState(false);
    const [isValidDateRange, setIsValidDateRange] = useState(true);
    return (
        <AdSpaceContext.Provider
            value={{
                videoUrls, setVideoUrls,
                imageUrls, setImageUrls,
                adspaceText, setAdspaceText,
                showAdSpaceBanner, setShowAdSpaceBanner,
                isValidDateRange, setIsValidDateRange
            }}
        >
            {children}
        </AdSpaceContext.Provider>
    );
};

export default AdSpaceProvider;
