import React from "react";

const EmailChips = ({ items, value, error, setItems, setValue, setError, placeHolder }) => {


    const isEmail = (email) => {
        // eslint-disable-next-line
        return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
    }


    const isInList = (email) => {
        return items.includes(email);
    }

    const isValid = (email) => {
        let error: any = null;
        if (isInList(email)) {
            error = `${email} has already been added.`
        }

        if (!isEmail(email)) {
            error = `${email} is not a valid email address.`
        }

        if (error) {
            setError(error)
            return false;
        }

        return true;
    }

    const handleKeyDown = evt => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();
            const tempValue = value.trim();
            if (tempValue && isValid(tempValue)) {
                const tempItem = items;
                tempItem.push(tempValue)
                setItems(tempItem);
                setValue("");
            }
        }
    };

    const handleChange = evt => {
        setValue(evt.target.value);
        setError(null);
    };

    const handleDelete = item => {
        setItems(items.filter(i => i !== item));
    };

    const handlePaste = evt => {
        evt.preventDefault();
        let paste = evt.clipboardData.getData("text");
        // eslint-disable-next-line
        let emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

        if (emails) {
            let toBeAdded = emails.filter(email => !isInList(email));
            const tempItem = [...items];
            tempItem.push(toBeAdded[0])
            setItems(tempItem);
        }
    };

    return (
        <>
            <input
                className={"form-control form-control-solid chipInput" + (error && " has-error")}
                value={value}
                placeholder={placeHolder ? placeHolder : "Type or paste email addresses and press `Enter`..."}
                onKeyDown={(e) => handleKeyDown(e)}
                onChange={(e) => handleChange(e)}
                onPaste={(e) => handlePaste(e)}
            />

            {error && <p className="error">{error}</p>}
            <div className="scroll-chips">
                {items.map(item => (
                    <div style={{ color: "white" }} className="tag-item mt-3 btn kt-subheader__btn-primary" key={item}>
                        {item}
                        <button
                            type="button"
                            className="button"
                            onClick={() => handleDelete(item)}
                        >
                            &times;
                        </button>
                    </div>
                ))}
            </div>
        </>
    )
}

export default EmailChips;