import React, { lazy, useState, useEffect } from "react";
import { Link, Switch, Route, Redirect, useLocation } from "react-router-dom";
import { toAbsoluteUrl, getContactLink } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import { useTranslation } from "react-i18next";
// import { useSelector } from "react-redux";


const Login = lazy(() =>
  import("./Login")
);

const Registration = lazy(() =>
  import("./Registration")
);

const ForgotPassword = lazy(() =>
  import("./ForgotPassword")
);

const ResetPassword = lazy(() =>
  import("./ResetPassword")
);

const CustomLogin = lazy(() =>
  import("./CustomLogin")
);

export default function AuthPage() {

  const { t, i18n } = useTranslation();

  const location = useLocation();
  const locale = i18n.language;
  const [activeRoute, setActiveRoute] = useState<string>("");

  useEffect(() => {
    if (location && location.pathname) {
      setActiveRoute(location.pathname);
    }
    else {
      setActiveRoute("");
    }
  }, [location]);

  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div
              className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
            >
              <div className="kt-grid__item">
                <Link to="/" className="kt-login__logo">
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/logos/logo-4.png")}
                    style={{
                      maxWidth: "7rem"
                    }}
                  />
                </Link>
              </div>
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                <div className="kt-grid__item kt-grid__item--middle">
                  <h3 className="kt-login__title">
                    {activeRoute.includes("/auth/registration") ? t('auth.headingRegister') : t('auth.headingLogin')}
                  </h3>
                  <h4 className="kt-login__subtitle" style={{ marginBottom:'1px'}}>
                    {activeRoute.includes("/auth/registration") ? t('auth.descriptionRegister') : t('auth.descriptionLogin')}
                  </h4>
                  {  activeRoute.includes("/auth/registration") &&
                   <h4 className="kt-login__subtitle">
                    {t('auth.descriptionRegister2') }
                  </h4>
                  }
                </div>
              </div>

              <div className="kt-grid__item">
                <div className="kt-login__info">
                  <div className="kt-login__copyright">
                    &copy; {new Date().getFullYear().toString()} AdConnector GmbH
                  </div>
                  <div className="kt-login__menu">
                    <a href={getContactLink(locale)} className="kt-link" target="_blank" rel="noopener noreferrer">
                      {t('auth.contactLabel')}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
              <Switch>
                <Route path="/auth/login" component={Login} />
                <Route path="/auth/registration" component={Registration} />
                <Route path="/auth/forgot-password" component={ForgotPassword} />
                <Route path="/auth/reset-password" component={ResetPassword} />
                <Route path="/auth/custom-login" component={CustomLogin} />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
