import React from "react";
import { useTranslation } from "react-i18next";

export default function MenuSection({ item }) {

  const { t } = useTranslation();

  return (
    <li className="kt-menu__section">
      <h4 className="kt-menu__section-text">{t(`aside.${item.section}`)}</h4>
      <i className="kt-menu__section-icon flaticon-more-v2" />
    </li>
  );
}
