import { createSlice } from "@reduxjs/toolkit";

const initialListState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [] as any,
    listForEdit: undefined,
    error: "",
    lastError: null
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const listSlice = createSlice({
    name: "lists",
    initialState: initialListState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = "";
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // getListById
        listFetched: (state, action) => {
            state.actionsLoading = false;
            state.listForEdit = action.payload.listForEdit;
            state.error = "";
        },
        // findLists
        listsFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = "";
            state.entities = entities;
            state.totalCount = totalCount;
        },
        // createList
        listCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = "";
            state.entities.push(action.payload);
        },
        // updateList
        listUpdated: (state, action) => {
            state.error = "";
            state.actionsLoading = false;
            state.entities = state.entities.map((entity: any) => {
                if (entity.id === action.payload.id) {
                    return action.payload;
                }
                return entity;
            });
        },
        // deleteList
        listDeleted: (state, action) => {
            state.error = "";
            state.actionsLoading = false;
            state.entities = state.entities.filter(el => el.id !== action.payload.id);
        },
        // deleteLists
        listsDeleted: (state, action) => {
            state.error = "";
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                (el: any) => !action.payload.ids.includes(el.id)
            );
        },
        // listsUpdateState
        listsStatusUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = "";
            const { ids, status } = action.payload;
            state.entities = state.entities.map((entity: any) => {
                if (ids.findIndex(id => id === entity.id) > -1) {
                    entity.status = status;
                }
                return entity;
            });
        }
    }
});
