import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core";
import { ReactComponent as TimelinePin } from "../../../../_metronic/layout/assets/layout-svg-icons/timeline-pin.svg";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "src/app/store";
import { fetchActivities } from "src/app/store/activity/activity.actions";
import { IUser } from "src/types/Types";

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: "auto",
        height: theme.spacing(3),
        borderRadius: 50,
    },
}));

const Timeline = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const user: IUser = useSelector((state: any) =>
        state.auth.user && state.auth.user.parent ? state.auth.user.parent : state.auth.user
    );
    const activies: any[] = useSelector(
        (state: RootState) => state.activites.data
    );
    const limit: number = useSelector(
        (state: RootState) => state.activites.limit
    );
    const isLoading: boolean = useSelector(
        (state: RootState) => state.activites.isLoading
    );

    useEffect(() => {
        if (activies.length < 5) dispatch(fetchActivities(limit));
    }, [activies, dispatch, limit]);

    const TimelineSkeleton = () => (
        <div className="timeline-item">
            <div className="timeline-icon symbol symbol-circle symbol-40px me-4">
                <Skeleton
                    animation="wave"
                    variant="circle"
                    width={40}
                    height={40}
                />
            </div>
            <div className="timeline-content mb-5">
                <Skeleton
                    animation="wave"
                    height={22}
                    width="50%"
                    style={{ marginBottom: 13 }}
                />
                <Skeleton
                    animation="wave"
                    height={20}
                    width="20%"
                    style={{ marginBottom: 6 }}
                />
            </div>
        </div>
    );

    return (
        <div className="timeline timeline-7">
            {activies.length > 0 ? (
                activies.map((log) => (
                    <div className="timeline-item" key={log.id}>
                        <div className="timeline-line w-40px"></div>
                        <div className="timeline-icon symbol symbol-circle symbol-40px me-4">
                            <div className="symbol-label bg-light">
                                <span className="svg-icon svg-icon-2 svg-icon-gray-500">
                                    <TimelinePin />
                                </span>
                            </div>
                        </div>
                        <div className="timeline-content mb-5">
                            <div className="overflow-auto pr-3">
                                <div className="fs-5 fw-bold mb-2">
                                    <p>
                                        {t(`activity.${log.action}`)}
                                        {log.page.url ? (
                                            <a href={log.page.url}>
                                                {" "}{t(log.page.title)}
                                            </a>
                                        ) : (
                                            <>{" "}{t(log.page.title)} </>
                                        )}
                                        {log.page.parentPage &&
                                            ` on ${t(log.page.parentPage)}`}
                                    </p>
                                    {log.from.length > 0 && (
                                        <ul>
                                            {log.from.map((product, index) => (
                                                <li key={index}>
                                                    {product.title}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <div className="d-flex align-items-center mt-1 fs-6">
                                    <div className="text-muted mr-2 fs-7">
                                        {t("activity.date", {
                                            date: new Date(log.createdAt),
                                        })}
                                    </div>
                                    <div
                                        data-bs-toggle="tooltip"
                                        data-bs-boundary="window"
                                        data-bs-placement="top"
                                        title={`${user.first_name} ${user.last_name}`}
                                    >
                                        <img
                                            alt={`${user.first_name} ${user.last_name}`}
                                            src={
                                                user.profilePic
                                                    ? user.profilePic
                                                    : user.avatar_url
                                            }
                                            className={classes.avatar}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            ) : !isLoading ? (
                <div>{t("activity.notFound")}</div>
            ) : (
                ""
            )}
            {isLoading
                ? [...Array(5)].map(() => {
                      const key = Math.floor(Math.random() * 90000) + 10000;
                      return (
                          <div key={key}>
                              <TimelineSkeleton />
                          </div>
                      );
                  })
                : ""}
        </div>
    );
};

export default Timeline;
