import { TypedUseSelectorHook, useSelector as useReduxSelector } from "react-redux";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import thunk from "redux-thunk";

import { metronic } from "../../_metronic";
import activeAccountReducer from "./activeAccount/activeAccountSlice";
import activitiesReducer from "./activity/activity.reducer";
import { authReducer, authSaga, fetchDataSaga } from "./auth";
import { dataReducer } from "./data";
import { listSlice } from "./listSlice";
import platformReducer from "./platform/platform.reducer";
import ticketReducer from "./ticket/ticket.reducer";

// Root Reducer
export const rootReducer = combineReducers({
    activeAccount: activeAccountReducer,
    auth: authReducer,
    data: dataReducer,
    list: listSlice.reducer,
    activites: activitiesReducer,
    platforms: platformReducer,
    builder: metronic.builder.reducer,
    tickets: ticketReducer,
});

// Root saga
export function* rootSaga() {
    yield all([authSaga(), fetchDataSaga()]);
}

const sagaMiddleware = createSagaMiddleware();
// Redux dev tools
const composeEnhancers = (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

// Store
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware, thunk)));

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof rootReducer>;
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export default store;
