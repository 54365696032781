import { makeStyles } from "@material-ui/core";

const LoaderStyle = makeStyles((theme) => ({
    loaderWrapper: {
        position: "absolute",
        zIndex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: "rgba(#FFF, 0.5)",
    },
    loader: {
        width: 24,
        height: 24,
    },
}));

export default LoaderStyle;
