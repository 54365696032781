import axios from "axios";

export function uploadFileWithProgress(params: any, onUploadProgress: any, uploadedFrom: string) {
    return axios.post("files/id", params, {
        params: { uploadedFrom: uploadedFrom },
        data: onUploadProgress,
    });
}

export function uploadFile(params: any, uploadedFrom: string) {
    return axios.post("files/id", params, { params: { uploadedFrom: uploadedFrom } });
}

export function uploadUserFile(id, params: any) {
    return axios.post(`files/user/${id}`, params);
}

export function uploadByUrl(url) {
    return axios.post(`files/upload/url`, { url: url });
}

export function uploadInvoice(params: any) {
    return axios.post(`files/invoice`, params);
}

export function uploadVideo(formData: any) {
    return axios.post("files/upload/video", formData);
}

export function uploadThumb(fileName: string, thumbnail: string) {
    return axios.post("files/upload/video/thumb", { fileName: fileName, fileData: thumbnail });
}
