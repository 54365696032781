import store from "../store";
import { postActivity } from "src/app/crud/activity.crud";
import { IActivityView } from "src/types/Types";
import { addActivity, setLimit } from "../store/activity/activity.actions";

const AddActivityAndUpdateLimit = (data: any) => {
    const state = store.getState();
    store.dispatch(addActivity(data));
    store.dispatch(setLimit(state.activites.limit + 1));
};

export const ViewPageActivity = (pageTitle: string, parentPage?: string) => {
    let activity: IActivityView = {
        action: "view",
        page: {
            title: pageTitle,
            url: window.location.pathname,
            parentPage: parentPage
        },
    };
    postActivity(activity).then((res) => {
        if (res.status === 200) AddActivityAndUpdateLimit(res.data);
    });
};

export const AddActivity = (pageTitle: string, parentPage?: string) => {
    let activity: IActivityView = {
        action: "added",
        page: {
            title: pageTitle,
            url: window.location.pathname,
            parentPage: parentPage
        },
    };
    postActivity(activity).then((res) => {
        if (res.status === 200) AddActivityAndUpdateLimit(res.data);
    });
};

export const UpdateActivity = (pageTitle: string, parentPage?: string) => {
    let activity: IActivityView = {
        action: "updated",
        page: {
            title: pageTitle,
            url: window.location.pathname,
            parentPage: parentPage
        },
    };
    postActivity(activity).then((res) => {
        if (res.status === 200) AddActivityAndUpdateLimit(res.data);
    });
};

export const StatusUpdateActivity = (pageTitle: string, parentPage?: string) => {
    let activity: IActivityView = {
        action: "updatedStatus",
        page: {
            title: pageTitle,
            url: window.location.pathname,
            parentPage: parentPage
        },
    };
    postActivity(activity).then((res) => {
        if (res.status === 200) AddActivityAndUpdateLimit(res.data);
    });
};

export const DeleteActivity = (pageTitle: string, parentPage?: string) => {
    let activity: IActivityView = {
        action: "deleted",
        page: {
            title: pageTitle,
            url: window.location.pathname,
            parentPage: parentPage
        },
    };
    postActivity(activity).then((res) => {
        if (res.status === 200) AddActivityAndUpdateLimit(res.data);
    });
};

export const CreateAccountActivity = (pageTitle: string) => {
    let activity: IActivityView = {
        action: "accountCreated",
        page: {
            title: pageTitle,
            url: window.location.pathname,
        },
    };
    postActivity(activity).then((res) => {
        if (res.status === 200) AddActivityAndUpdateLimit(res.data);
    });
};

export const ConnectAccountActivity = (pageTitle: string) => {
    let activity: IActivityView = {
        action: "accountConnected",
        page: {
            title: pageTitle,
            url: window.location.pathname,
        },
    };
    postActivity(activity).then((res) => {
        if (res.status === 200) AddActivityAndUpdateLimit(res.data);
    });
};

export const RemoveAccountActivity = (pageTitle: string) => {
    let activity: IActivityView = {
        action: "accountRemoved",
        page: {
            title: pageTitle,
            url: window.location.pathname,
        },
    };
    postActivity(activity).then((res) => {
        if (res.status === 200) AddActivityAndUpdateLimit(res.data);
    });
};

export const ActionActivity = (pageTitle: string, parentPage?: string) => {
    let activity: IActivityView = {
        action: "action",
        page: {
            title: pageTitle,
            url: window.location.pathname,
            parentPage: parentPage
        },
    };
    postActivity(activity).then((res) => {
        if (res.status === 200) AddActivityAndUpdateLimit(res.data);
    });
}

export const ViewModalActivity = (pageTitle: string, url: string = "") => {
    let activity: IActivityView = {
        action: "view",
        page: {
            title: pageTitle,
            url: url
        },
    };
    postActivity(activity).then((res) => {
        if (res.status === 200) AddActivityAndUpdateLimit(res.data);
    });
};

export const ActionModalActivity = (pageTitle: string) => {
    let activity: IActivityView = {
        action: "action",
        page: {
            title: pageTitle,
            url: ""
        },
    };
    postActivity(activity).then((res) => {
        if (res.status === 200) AddActivityAndUpdateLimit(res.data);
    });
};