import { useEffect } from 'react';

function ScrollToTop() {
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return null;
}

export default ScrollToTop;