import React from "react";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";

// let linkedinCheck = false;
export default class MenuSubmenu extends React.Component {

  checkLindeinPlan = (user) => {
    // if (user && user.sub && user.sub.line_items) {
    //   user.sub.line_items.forEach((rec) => {
    //     if (rec.name.includes("Business Ads")){
    //       linkedinCheck = true;
    //     }
    //   });
    // }
  }



  render() {
    const { item, currentUrl, layoutConfig, userData } = this.props;
    this.checkLindeinPlan(userData);
    return (
      <ul className="kt-menu__subnav">
        {item.submenu.map((child, index) => {
          //     if (child.title === "linkedin" && !linkedinCheck) {
          //     return null
          //   } else {
          return (
            <React.Fragment key={index}>
              {child.section && (
                <MenuSection
                  item={child}
                  parentItem={item}
                  currentUrl={currentUrl}
                />
              )}

              {child.separator && (
                <MenuItemSeparator
                  item={child}
                  parentItem={item}
                  currentUrl={currentUrl}
                />
              )}

              {child.title && (
                <MenuItem
                  item={child}
                  parentItem={item}
                  currentUrl={currentUrl}
                  layoutConfig={layoutConfig}
                />
              )}
            </React.Fragment>
          )
          //  }
        })}
      </ul>
    );
  }
}
