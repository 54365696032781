import store from "..";
import { getActivity } from "src/app/crud/activity.crud";
import { ADD_ACTIVITY, IS_FETCH_ACTIVIES, SET_ACTIVITIES, SET_LIMIT, SET_NEXT_ACTIVITIES, RESET_ACTIVITY } from "./activity.types";

export const fetchActivities = (limit: number) => {
  return async function (dispatch) {
      try {
          dispatch(setIsLoading(true));
          const activities: any = await getActivity(limit);
          const activityData = activities.data;
          if(activityData.length > 0) {
            dispatch(setActivities(activityData));
          }
          dispatch(setIsLoading(false));
        }
        catch (error) {
          dispatch(setIsLoading(false));
      };
  };
};

export const fetchNextActivities = (limit: number) => {
  return async function (dispatch) {
    try {
      dispatch(setIsLoading(true));
      const activities: any = await getActivity(limit);
      const activityData = activities.data;
      if(activityData.length > 0) { dispatch(setNextActivities(activityData)) }
      dispatch(setLimit(store.getState().activites.data.length));
      dispatch(setIsLoading(false));
    } catch (error) { 
      dispatch(setIsLoading(false));
    }
  }
}

export const setActivities = (activities = null) => {
  if (activities) {
    return {
      type: SET_ACTIVITIES,
      payload: activities,
    };
  }
};

export const setNextActivities = (activities = null) => {
  if (activities) {
    return {
      type: SET_NEXT_ACTIVITIES,
      payload: activities,
    };
  }
};

export const setLimit = (limit: number) => {
    return {
      type: SET_LIMIT,
      payload: limit,
    };
};

export const setIsLoading = (value) => {
  return {
    type: IS_FETCH_ACTIVIES,
    payload: value,
  };
};

export const addActivity = (value) => {
  return {
    type: ADD_ACTIVITY,
    payload: value,
  }
}

export const resetActivity = () => {
  return { type: RESET_ACTIVITY }
}