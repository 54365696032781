import axios from "axios";

export function generateTaboolaOAuth() {
    return axios.get("/taboola_ads/auth_url");
}

export function getTokenByCode(code: string) {
    return axios.post("/users/id/taboola_ads/token", { code: code });
}

export function sharePostOnMeta() {
    return axios.post("/users/id/taboola_ads/share_post");
}

export function uploadImageOnTaboola(file: any, onUploadProgress) {
    return axios.post("/users/id/taboola_ads/upload_image", file, onUploadProgress);
}

export function updateActiveAccount(client: any, status: any, value: boolean) {
    return axios.put("/users/id/taboola_ads/active_account", { client: client, status: status, value: value });
}

export function getAccountsList() {
    return axios.get("/users/id/taboola_ads/accounts");
}

export function createNewAccount(input) {
    return axios.post("/users/id/taboola_ads/account", input);
}

export function getSearchCampaigns(input) {
    return axios.get("/users/id/taboola_ads/search/campaigns", { params: input });
}

export function createSearchCampaign(campaign) {
    return axios.post("/users/id/taboola_ads/search/campaigns", campaign);
}

export function changeSearchCampaignStatus(data: { campaignId: any; status: string }) {
    return axios.put("/users/id/taboola_ads/search/campaigns/status", data);
}

export function updateSearchCampaign(data: { campaignId: any; name: string }) {
    return axios.put("/users/id/taboola_ads/search/campaigns/update", data);
}

export function deleteSearchCampaigns(cid: any) {
    return axios.delete("/users/id/taboola_ads/search/campaigns/delete", { data: cid });
}

export function updateSearchCampaignBudget(budget) {
    return axios.put("/users/id/taboola_ads/search/campaign_budget", budget);
}

export function getSearchAdGroups(input) {
    return axios.get("/users/id/taboola_ads/search/ad_groups", { params: input });
}

export function createSearchAdGroup(adGroup) {
    return axios.post("/users/id/taboola_ads/search/ad_groups", adGroup);
}

export function updateSearchAdGroup(adGroups) {
    return axios.put("/users/id/taboola_ads/search/ad_groups", adGroups);
}

export function deleteSearchAdGroups(resources) {
    return axios.post("/users/id/taboola_ads/search/ad_groups/delete", resources);
}

export function getSearchAdGroupAds(input) {
    return axios.get("/users/id/taboola_ads/search/ad_group_ads", { params: input });
}

export function createSearchAdGroupAd(adgroupAd) {
    return axios.post("/users/id/taboola_ads/search/ad_group_ads", adgroupAd);
}

export function changeSearchAdGroupAdsStatus(data: { adGroupAdsId: any; campaignId: any; status: string }) {
    return axios.put("/users/id/taboola_ads/search/ad_group_ads/status", data);
}

export function updateSearchAdGroupAds(data: { adGroupAdsId: any; title: string; targetUrl: any; campaignId: any }) {
    return axios.put("/users/id/taboola_ads/search/ad_group_ads/update", data);
}

export function deleteSearchAdGroupAds(resources) {
    return axios.post("/users/id/taboola_ads/search/ad_group_ads/delete", resources);
}

export function postTaboolaAdWizard(input: any) {
    return axios.post("/users/id/taboola_ads/taboola_ad_wizard", input);
}

export function getBusinessList() {
    return axios.get("/users/id/taboola_ads/businesses");
}

export function removeTaboolaAccount(input: any) {
    return axios.post("/users/id/taboola_ads/remove_account", input);
}

export function switchTaboolaAccount(account: any) {
    return axios.put("/users/id/taboola_ads/switch_account", { account: account });
}