import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "src/_metronic";

const tempImages = [
    toAbsoluteUrl("/media/products/prioritySuppot1.png"),
    toAbsoluteUrl("/media/products/prioritySuppot2.png"),
    toAbsoluteUrl("/media/products/prioritySuppot3.png")
]
const InfoModal = ({ show, onHide, title, message, message2, images = tempImages }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <Modal style={{ zIndex: 9999 }} show={show} onHide={onHide} animation={true}>
            <Modal.Header closeButton>
                <Modal.Title>{t(`${title}`)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t(`${message}`)}
                <br />
                {message2 && message2.includes('€') && <br />}
                {message2 && t(`${message2}`)}
                <br />
                {title === "Priority Support" && images && images.length > 0 && <Carousel prevIcon={<span aria-hidden="true" className="carousel-control-custom-prev-icon" />} nextIcon={<span aria-hidden="true" className="carousel-control-custom-next-icon" />} activeIndex={index} onSelect={handleSelect}>
                    {images.map((file, idx) => (
                        <Carousel.Item key={idx} >
                            <img
                                height="350px" width="auto"
                                className="d-block w-100"
                                src={file}
                                alt={`${idx} slide`}
                            />
                        </Carousel.Item>
                    ))}
                </Carousel>}
            </Modal.Body>
            {title === t("categoryList.onlineShop") && (
                <Modal.Footer>
                    <Button
                        variant="success"
                        onClick={() => history.push("/integrations/merchant")}
                    >
                        {t("adWizard.connect")}
                    </Button>
                </Modal.Footer>
            )}
        </Modal >
    )
}

export default InfoModal;