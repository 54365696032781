import { PLATFORMS } from "src/types/enums";

export const VALIDATE_TOKEN = "VALIDATE_TOKEN";
export const SET_ACCOUNT = "SET_ACCOUNT";
export const SET_ALL_ACCOUNTS = "SET_ALL_ACCOUNTS";
export const RESET_PLATFORM = "RESET_PLATFORM";
export const FETCH_PLATFORMS = "FETCH_PLATFORMS";
export const GET_TEMPLATES = "GET_TEMPLATES";

export const PLATFORM_LIST = [
    PLATFORMS.GOOGLE_ADS,
    PLATFORMS.GOOGLE_MERCHANT,
    PLATFORMS.GOOGLE_ANALYTICS,
    PLATFORMS.FACEBOOK,
    PLATFORMS.INSTAGRAM,
    PLATFORMS.TWITTER,
    PLATFORMS.LINKEDIN,
    PLATFORMS.TABOOLA,
];
