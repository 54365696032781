import React, { useReducer, useContext, useEffect } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS, CallBackProps } from "react-joyride";
import { useTranslation } from "react-i18next";
import { TourContext } from "src/app/context/TourContext";

const INITIAL_STATE = {
    key: new Date(),
    run: false,
    continuous: true,
    loading: false,
    stepIndex: 0,
    steps: [],
    disableOverlayClose: true,
    disableCloseOnEsc: true,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "START":
            return { ...state, run: true };
        case "RESET":
            return { ...state, stepIndex: 0, run: false };
        case "STOP":
            return { ...state, run: false };
        case "NEXT_OR_PREV":
            return { ...state, stepIndex: action.payload.stepIndex };
        case "RESTART":
            return {
                ...state,
                stepIndex: 0,
                run: true,
                loading: false,
                key: new Date()
            };
        default:
            return state;
    }
};

const toggleSidebarClass = (shouldOpen: boolean) => {
    const parent: any = document.querySelector('.connector-li');
    if (shouldOpen) {
        parent.classList.add('kt-menu__item--open');
        const submenu: any = parent.querySelector('.kt-menu__submenu');
        if (submenu) submenu.style.display = 'flex';
    } else {
        parent.classList.remove('kt-menu__item--open');
        const submenu: any = parent.querySelector('.kt-menu__submenu');
        if (submenu) submenu.style.display = 'none';
    }
};

const toggleSidebarClass1 = (shouldOpen: boolean) => {
    const parent2: any = document.querySelector('.database-li');
    if (shouldOpen) {
        parent2.classList.add('kt-menu__item--open');
        const submenu2: any = parent2.querySelector('.kt-menu__submenu');
        if (submenu2) submenu2.style.display = 'flex';
    } else {
        parent2.classList.remove('kt-menu__item--open');
        const submenu2: any = parent2.querySelector('.kt-menu__submenu');
        if (submenu2) submenu2.style.display = 'none';
    }
};

const toggleSidebarClass2 = (shouldOpen: boolean) => {
    const parent3: any = document.querySelector('.googleads-li');
    if (shouldOpen) {
        parent3.classList.add('kt-menu__item--open');
        const submenu3: any = parent3.querySelector('.kt-menu__submenu');
        if (submenu3) submenu3.style.display = 'flex';
    } else {
        parent3.classList.remove('kt-menu__item--open');
        const submenu3: any = parent3.querySelector('.kt-menu__submenu');
        if (submenu3) submenu3.style.display = 'none';
    }
};

export const closeAllDropdowns = () => {
    toggleSidebarClass(false);
    toggleSidebarClass1(false);
    toggleSidebarClass2(false);
    const element = document.getElementsByClassName('scrollbar-container');
    element[0].scrollTo(0, 0);
}

export const sideBar = () => {
    const sideBarToggle: any = document.body
    if (!sideBarToggle.classList.contains('.kt-aside--minimize')) {
        sideBarToggle.classList.add('kt-aside--minimize');
    }
}

const removeSideBarClass = () => {
    const sideBarToggle = document.body;
    if (sideBarToggle.classList.contains('kt-aside--minimize')) {
        sideBarToggle.classList.remove('kt-aside--minimize');
    }
};

const GeneralTour = () => {
    const { t } = useTranslation();
    const { stepIndexState, setStepIndexState, runTour, setRunTour } = useContext(TourContext);
    const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
    const TOUR_STEPS = [
        {
            target: "#kt_aside_toggler",
            content: (<>
                <h5 style={{ marginBottom: '20px' }}>{t('Tour.connector.step1')}</h5>
                <p style={{ marginBottom: '0px' }}>{t('Tour.connector.desc')}</p>
            </>),
            disableBeacon: true,
            spotlightClicks: true,
            placement: 'right',
            styles: {
                buttonNext: {
                    display: 'none',
                },
                tooltip:{
                    marginTop: "10px"
                }
            }
        },
        {
            target: ".connector-li",
            content: (<> 
                <h5 style={{ marginBottom: '20px' }}>{t('Tour.connector.step2')}</h5>
                <p style={{ marginBottom: '0px' }}>{t('Tour.connector.desc1')}</p>
            </>),
            spotlightClicks: true,
            placement: 'right',
            styles: {
                buttonNext: {
                    display: 'none',
                },
            }
        },
        {
            target: ".googleads-class",
            placement: 'right',
            content: (<><h6 style={{ marginBottom: '0px', fontSize: '15px' }}>{t('Tour.connector.step3')}</h6></>),
        },
        {
            target: ".metaads-class",
            placement: 'right',
            content: (<><h6 style={{ marginBottom: '0px', fontSize: '15px' }}>{t('Tour.connector.step4')}</h6></>),
        },
        {
            target: ".ai-class",
            content: (<><h6 style={{ marginBottom: '0px', fontSize: '15px' }}>{t('Tour.ai.step1')}</h6></>),
        },
        {
            target: ".adwizard-class",
            content: (<><h6 style={{ marginBottom: '0px', fontSize: '15px' }}>{t('Tour.adWizard.step1')}</h6></>),
        },
        {
            target: ".postwizard-class",
            content: (<><h6 style={{ marginBottom: '0px', fontSize: '15px' }}>{t('Tour.postWizard.step1')}</h6></>),
        },
        {
            target: ".textai-class",
            content: (<><h6 style={{ marginBottom: '0px', fontSize: '15px' }}>{t('Tour.textAi.step1')}</h6></>),
        },
        {
            target: ".database-li",
            placement: 'right',
            content: (<><h6 style={{ marginBottom: '0px', fontSize: '15px' }} >{t('Tour.competitors.step1')}</h6></>),
            spotlightClicks: true,
            styles: {
                buttonNext: {
                    display: 'none',
                }
            }
        },
        {
            target: ".competitor-class",
            placement: 'right',
            content: (<><h6 style={{ marginBottom: '0px', fontSize: '15px' }}>{t('Tour.competitors.step2')}</h6></>),
        },
        {
            target: ".search-li",
            placement: 'right',
            content: (<><h6 style={{ marginBottom: '0px', fontSize: '15px' }}>{t('Tour.searchTerms.step1')}</h6></>),
        },
        {
            target: ".search-li",
            placement: 'right',
            content: (<><h6 style={{ marginBottom: '0px', fontSize: '15px' }}>{t('Tour.searchTerms.step2')}</h6></>),
        },
        {
            target: ".search-li",
            placement: 'right',
            content: (<><h6 style={{ marginBottom: '0px', fontSize: '15px' }}>{t('Tour.searchTerms.step3')}</h6></>),
        },
        {
            target: ".googleads-li",
            placement: 'right',
            content: (<>
            <h5 style={{ marginBottom: '20px' }}>{t('Tour.searchCampaigns.step1')}</h5>
            <p style={{ marginBottom: '0px' }}>{t('Tour.searchCampaigns.desc')}</p>
            </>),
            spotlightClicks: true,
            styles: {
                buttonNext: {
                    display: 'none',
                }
            }
        },
        {
            target: ".googleads-searchclass",
            placement: 'right',
            content: (<><h6 style={{ marginBottom: '0px', fontSize: '15px' }}>{t('Tour.searchCampaigns.step2')}</h6></>),
        },
        {
            target: ".googleads-searchclass",
            placement: 'right',
            content: (<><h6 style={{ marginBottom: '0px', fontSize: '15px' }}>{t('Tour.searchCampaigns.step3')}</h6></>),
        },
        {
            target: ".googleshopping-class",
            placement: 'right',
            content: (<><h6 style={{ marginBottom: '0px', fontSize: '15px' }}>{t('Tour.shoppingCampaigns.step1')}</h6></>),
        },
        {
            target: ".upgrade-class",
            content: (<><h6 style={{ marginBottom: '0px', fontSize: '15px' }}>{t('Tour.upgrades.step1')}</h6></>),
        },
        {
            target: ".tipster-class",
            content: (<><h6 style={{ marginBottom: '0px', fontSize: '15px' }}>{t('Tour.tipster.step1')}</h6></>),
        },
        {
            target: ".optimizer-li",
            placement: 'right',
            content: (<><h6 style={{ marginBottom: '0px', fontSize: '15px' }}>{t('Tour.optimizer.step1')}</h6></>),
        },
        {
            target: ".support-class",
            content: (<><h6 style={{ marginBottom: '0px', fontSize: '15px' }}>{t('Tour.support.step1')}</h6></>),
        },
        {
            target: ".tutorial-class",
            content: (<><h6 style={{ marginBottom: '0px', fontSize: '15px' }}>{t('Tour.tutorial.step1')}</h6></>),
        },
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
        if (runTour) dispatch({ type: "START" });
    }, [runTour, dispatch]);

    const callback = (data: CallBackProps) => {
        const { index, type, status, action } = data;
        if (
            action === ACTIONS.CLOSE ||
            (status === STATUS.SKIPPED && tourState.run) ||
            status === STATUS.FINISHED
        ) {
            dispatch({ type: "RESET" });
            setRunTour(false);
            setStepIndexState(0);
            closeAllDropdowns();
            sideBar();
            window.scrollTo(0, 0);
        } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
            const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
            if (action === ACTIONS.NEXT && (index === 11)) {
                const element = document.getElementsByClassName('scrollbar-container');
                element[0].scrollTo(0, 200);
            }
            if (action === ACTIONS.PREV && (index === 12)) {
                const element = document.getElementsByClassName('scrollbar-container');
                element[0].scrollTo(0, -200);
            }
            if (action === ACTIONS.PREV && index === 4) {
                toggleSidebarClass(true);
            } else if (action === ACTIONS.NEXT && index === 3) {
                toggleSidebarClass(false);
            } else if (action === ACTIONS.PREV && index === 2) {
                toggleSidebarClass(false);
            }
            if (action === ACTIONS.PREV && index === 10) {
                toggleSidebarClass1(true);
            } else if (action === ACTIONS.NEXT && index === 9) {
                toggleSidebarClass1(false);
            } else if (action === ACTIONS.PREV && index === 9) {
                toggleSidebarClass1(false);
            }
            if (action === ACTIONS.PREV && index === 17) {
                toggleSidebarClass2(true);
            } else if (action === ACTIONS.NEXT && index === 16) {
                toggleSidebarClass2(false);
            } else if (action === ACTIONS.PREV && index === 14) {
                toggleSidebarClass2(false);
            }
            if (action === ACTIONS.PREV && index === 1) {
                sideBar();
            }
            if (action === ACTIONS.NEXT && index === 0) {
                removeSideBarClass();
            }
            setStepIndexState(stepIndex);
        }
    };

    return (
        <>
            <JoyRide
                key={tourState.key}
                {...tourState}
                run={runTour}
                callback={callback}
                stepIndex={stepIndexState}
                steps={TOUR_STEPS}
                showSkipButton={true}
                styles={{
                    spotlight: { backgroundColor: "#a2a2a2" },
                    overlay: { backgroundColor: "rgba(0, 0, 0, 0)" },
                    tooltip: {background: 'linear-gradient(150deg, #46bef7 0%, #5385e1 100%)',
                              color: '#fff'},
                    options: {
                                arrowColor: '#47bbf6'
                    },
                    tooltipContainer: {
                        textAlign: "left",
                    },
                    buttonBack: {
                        marginRight: 10,
                        color: '#fff'
                    },
                    buttonNext: {
                        background: '#fff',
                        color: '#5d78ff'
                    },
                    buttonSkip:{
                        color: '#fff'
                    },
                    buttonClose:{
                        color: '#fff'
                    }
                }}
                locale={{
                    back: t('Tour.buttontext.back'),
                    last: t('Tour.buttontext.last'),
                    next: t('Tour.buttontext.next'),
                    skip: t('Tour.buttontext.skip'),
                }}
            />
        </>
    );
};

export default GeneralTour;
