import React, { useEffect } from "react";
import { Modal, LinearProgress } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import { toAbsoluteUrl } from "src/_metronic";

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: "100%",
        maxWidth: 500,
        height: "100%",
        maxHeight: 250,
        backgroundColor: "#1e1e2d",
        borderRadius: "0.50rem",
        padding: theme.spacing(4),
        outline: "none",
        overflow: 'hidden'
    },
    heading: {
        textAlign: "center",
        color: 'white',
    },
    imageContainer: {
        textAlign: 'center',
    },
    img: {
        maxWidth: "275px",
        "@media (max-width: 375px)": {
            maxWidth: "200px",
        },
    }
}));

const ReviewModal = ({ show, onClose, title }) => {

    const { t } = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        let timeoutId;
        if (show) {
            timeoutId = setTimeout(() => {
                onClose();
            }, 8000);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [show, onClose]);

    return (
        <Modal
            open={show}
            onClose={onClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            className={classes.modal}
        >
            <div className={classes.paper}>
                <h5 className={classes.heading}>{title && t(`${title}`)}</h5>
                <br />
                <LinearProgress value={100} variant="determinate" />
                <div className={`${classes.imageContainer} kt-sc__bg mt-3 mb-2`}>
                    <img alt="Logo" src={toAbsoluteUrl("/media/logos/logo-light.png")} className={classes.img} />
                </div>
            </div>
        </Modal>
    );
}

export default ReviewModal;