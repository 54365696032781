import React, { useState, useReducer} from "react";
import { useHistory } from 'react-router-dom';
import { closeAllDropdowns, sideBar } from "../pages/home/GeneralTour";

export const TourContext = React.createContext();

const TourProvider = (props) => {
    const [stepIndexState, setStepIndexState] = useState(0);
    const [runTour, setRunTour] = useState(false);
    const history = useHistory();

    const INITIAL_STATE = {
        key: new Date(),
        run: false,
        continuous: true,
        loading: false,
        stepIndex: 0,
        steps: [],
        disableOverlayClose: true,
        disableCloseOnEsc: true,
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case "START":
                return {
                    ...state,
                    stepIndex: 0,
                    run: true,
                    loading: false,
                    key: new Date()
                };
            default:
                return state;
        }
    };
    const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
    const startTour = () => {
        setRunTour(true);
        dispatch({ type: "START" });
        history.push('/dashboard');
        setStepIndexState(0);
        closeAllDropdowns();
        sideBar();
    };
    return <TourContext.Provider value={{ stepIndexState, setStepIndexState, tourState, dispatch, startTour, runTour, setRunTour }}>{props.children}</TourContext.Provider>;
};

export default TourProvider;