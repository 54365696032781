import axios from "axios";

export function generateTiktokOAuth() {
    return axios.get("/tiktok_ads/auth_url");
}

export function getTokenByCode(code: string) {
    return axios.post("/users/id/tiktok_ads/token", { code: code });
}

export function sharePostOnTiktok() {
    return axios.post("/users/id/tiktok_ads/share_post");
}

export function uploadMediaOnTiktok(file: any, onUploadProgress) {
    return axios.post("/users/id/tiktok_ads/upload_image", file, onUploadProgress);
}

export function updateActiveAccount(client, status, value) {
    return axios.put("/users/id/tiktok_ads/active_account", { client: client, status: status, value: value });
}

export function getAccountsList() {
    return axios.get("/users/id/tiktok_ads/accounts");
}

export function createNewAccount(input) {
    return axios.post("/users/id/tiktok_ads/account", input);
}

export function getSearchCampaigns(input) {
    return axios.get("/users/id/tiktok_ads/search/campaigns", { params: input });
}

export function createSearchCampaign(campaign) {
    return axios.post("/users/id/tiktok_ads/search/campaigns", campaign);
}

export function updateSearchCampaign(campaigns) {
    return axios.put("/users/id/tiktok_ads/search/campaigns", campaigns);
}

export function deleteSearchCampaigns(resources) {
    return axios.post("/users/id/tiktok_ads/search/campaigns/delete", resources);
}

export function updateSearchCampaignBudget(budget) {
    return axios.put("/users/id/tiktok_ads/search/campaign_budget", budget);
}

export function getSearchAdGroups(input) {
    return axios.get("/users/id/tiktok_ads/search/ad_groups", { params: input });
}

export function createSearchAdGroup(adGroup) {
    return axios.post("/users/id/tiktok_ads/search/ad_groups", adGroup);
}

export function updateSearchAdGroup(adGroups) {
    return axios.put("/users/id/tiktok_ads/search/ad_groups", adGroups);
}

export function deleteSearchAdGroups(resources) {
    return axios.post("/users/id/tiktok_ads/search/ad_groups/delete", resources);
}

export function getSearchAdGroupAds(input) {
    return axios.get("/users/id/tiktok_ads/search/ad_group_ads", { params: input });
}

export function createSearchAdGroupAd(adgroupAd) {
    return axios.post("/users/id/tiktok_ads/search/ad_group_ads", adgroupAd);
}

export function updateSearchAdGroupAds(adGroupAds) {
    return axios.put("/users/id/tiktok_ads/search/ad_group_ads", adGroupAds);
}

export function updateSearchAdGroupAd(adgroupAd) {
    return axios.put("/users/id/tiktok_ads/search/ad_group_ad", adgroupAd);
}

export function deleteSearchAdGroupAds(resources) {
    return axios.post("/users/id/tiktok_ads/search/ad_group_ads/delete", resources);
}

export function postTiktokAdWizard(input: any) {
    return axios.post("/users/id/tiktok_ads/tiktok_ad_wizard", input);
}

export function getPagesList(input: any) {
    return axios.get("/users/id/tiktok_ads/businesses", { params: input });
}