/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, KeyboardEvent, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "src/app/store";
import { ReactComponent as History } from "../../../_metronic/layout/assets/layout-svg-icons/history-icon.svg";
import { Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Timeline from "src/app/pages/user/activity/timeline";
import PerfectScrollbar from "react-perfect-scrollbar";
import { fetchNextActivities } from "src/app/store/activity/activity.actions";
import { ReactComponent as InfoIcon } from "../../../_metronic/layout/assets/layout-svg-icons/info.svg";
import InfoModal from "../../partials/layout/InfoModals";
import { isEmptyObject } from "jquery";

const useStyles = makeStyles((theme) => ({
    card: {
        width: 900,
        height: "100vh",
        "&.card-custom > .card-header .card-title": {
            color: "#48465b",
        },
        "&.card > .card-body": {
            padding: "2rem 2.25rem",
        },
    },
    cardBody: {
        height: "calc(100vh - 130px)",
        overflow: "hidden",
        "& > div": {
            height: "100%",
        },
    },
    btnClose: {
        backgroundColor: "#f7f8fa",
        transition: "all .3s",
        borderRadius: "4px",
        border: 0,
        width: 28,
        height: 28,
        "& i": {
            fontSize: "0.8rem",
            color: "#74788d",
        },
        "&:hover": {
            background: "linear-gradient(150deg, rgb(70, 190, 247) 0%, rgb(83, 133, 225) 100%)",
            "& i": {
                color: "#ffffff",
            },
        },
    },
}));

const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: true,
};

export default function ProfileHistory() {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const limit: number = useSelector((state: RootState) => state.activites.limit);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const user = useSelector((state: any) =>
        state.auth.user && state.auth.user.parent ? state.auth.user.parent : state.auth.user
    );

    const toggleDrawer = (event: KeyboardEvent | MouseEvent) => {
        if (
            event.type === "keydown" &&
            ((event as KeyboardEvent).key === "Tab" ||
                (event as KeyboardEvent).key === "Shift")
        ) {
            return;
        }

        setOpen(!open);
    };

    const nextActivities = () => {
        dispatch(fetchNextActivities(limit + 5));
    };

    return (
        <>
            <div className="kt-header__topbar-item kt-header__topbar-item--profile-history">
                <div className="kt-header__topbar-wrapper">
                    <span
                        className="kt-header__topbar-icon kt-pulse kt-pulse--brand"
                        onClick={toggleDrawer}
                    >
                        <span
                            className={isEmptyObject(user.sub)
                                ? "topbarSvgHistoryIcon kt-header__topbar-icon svg g"
                                : "kt-svg-icon"
                            }
                        >
                            <History />
                        </span>
                    </span>
                </div>
                <Drawer
                    variant="temporary"
                    anchor="right"
                    open={open}
                    onClose={toggleDrawer}
                >
                    <div className={`card card-custom ${classes.card}`}>
                        <div className="card-header">
                            <h3 className="card-title fw-bolder">
                                {t("user.history.title")}
                                <div className="mt-1" onClick={() => handleShow()}>
                                    <InfoIcon />
                                </div>
                            </h3>

                            <div className="card-toolbar">
                                <button
                                    type="button"
                                    className={classes.btnClose}
                                    onClick={toggleDrawer}
                                >
                                    <span className="svg-icon svg-icon-1">
                                        <i className="flaticon2-delete" />
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div
                            className={`card-body position-relative ${classes.cardBody}`}
                        >
                            <PerfectScrollbar
                                options={perfectScrollbarOptions}
                                style={{ position: "relative" }}
                            >
                                <Timeline />
                            </PerfectScrollbar>
                        </div>
                        <div className="card-footer text-center">
                            <button
                                className="btn text-primary fw-bolder"
                                onClick={nextActivities}
                            >
                                {t("activity.viewNext")}
                                <span className="svg-icon svg-icon-3 svg-icon-primary">
                                    <ArrowForwardIcon />
                                </span>
                            </button>
                        </div>
                    </div>
                </Drawer>
            </div>
            <InfoModal show={show} onHide={handleClose} title={"user.history.title"} message={"user.history.info"} message2={undefined} />
        </>
    );
}
