import React from "react";
// { useState, useEffect }
import { connect } from "react-redux";
import objectPath from "object-path";
import * as builder from "../../ducks/builder";
// import i18n from "src/i18n";
// import { useTranslation } from "react-i18next";

export function Footer(props) {

    // const { t } = useTranslation();
    const today = new Date().getFullYear();
    // const [urlPath, setUrlPath] = useState("");
    // useEffect(() => {
    //     i18n.language === "de" ? setUrlPath("https://adconnector.com/de/support/") : setUrlPath("https://adconnector.com/support/");
    // }, [t]);

    return (
        <div
            className={`kt-footer ${props.footerClasses} kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop`}
            id="kt_footer"
        >
            <div className={`kt-container ${props.footerContainerClasses}`}>
                <div className="kt-footer__copyright">
                    {today.toString()}&nbsp;&copy;&nbsp;
            <a
                        href="https://adconnector.de"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="kt-link"
                    >
                        AdConnector GmbH
            </a>
                </div>
                {/* <div className="kt-footer__menu">
                    <a
                        href={urlPath}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="kt-footer__menu-link kt-link"
                    >
                        Support
            </a>
                </div> */}
            </div>
        </div>
    );
}

const mapStateToProps = store => ({
    fluid:
        objectPath.get(store.builder.layoutConfig, "footer.self.width") === "fluid",
    footerClasses: builder.selectors.getClasses(store, {
        path: "footer",
        toString: true
    }),
    footerContainerClasses: builder.selectors.getClasses(store, {
        path: "footer_container",
        toString: true
    })
});


export default connect(mapStateToProps)(Footer);
