import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import EmailChips from "../layout/emailChips";
import Tooltip from '../layout/toolTip';
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";
import { IUser } from "src/types/Types";
import { useSelector, useDispatch } from "react-redux";
import { updateMe as updateMeAction } from "../../store/auth";
import { updateCustomMeta } from "src/app/crud/auth.crud";
import { ActionActivity } from "src/app/utils/activityLog";
import { uploadFile } from "src/app/crud/files.crud";
import { ReactComponent as InfoIcon } from "../../../_metronic/layout/assets/layout-svg-icons/info.svg";
import { UploadedFrom } from "../helpers/DragAndDropFiles";

const OnBoarding = ({ show, onHide, title, setOnborading, isLoading = false }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const allSteps = [
        { no: 1, title: t("partials.onBoarding.logo"), desc: t("partials.onBoarding.personalizeAccount"), class: "current" },
        { no: 2, title: t("partials.onBoarding.members"), desc: t("partials.onBoarding.inviteTeam"), class: "" },
        { no: 3, title: t("partials.onBoarding.invoices"), desc: t("partials.onBoarding.received"), class: "" },
        { no: 4, title: t("partials.onBoarding.callAction"), desc: t("partials.onBoarding.helpfulInfo"), class: "" }
    ];
    const user: IUser = useSelector((state: any) =>
        state.auth.user && state.auth.user.parent ? state.auth.user.parent : state.auth.user
    );
    const [steps, setSteps] = useState(allSteps);
    const [imageUrl, setImageUrl] = useState(toAbsoluteUrl("/media/users/default.jpg"));
    const [image, setImage]: any = useState<string | Blob>();
    const [imagePreview, setImagePreview] = useState<string>();
    const [memberEmails, setMembersEmails]: any = useState([]);
    const [memberValue, setMemberValue]: any = useState("");
    const [membererror, setMemberError]: any = useState(null);
    const [invoiceEmails, setInvoiceEmails]: any = useState([]);
    const [invoiceValue, setInvoiceValue]: any = useState("");
    const [invoiceError, setInvoiceError]: any = useState(null);
    const registerUrl = "https://app.adconnector.com/auth/registration";
    const addMemberUrl = "https://app.adconnector.com/user/members";

    const nextStep = (idx: any) => {
        let tempStep = [...steps];
        if (idx !== undefined && typeof idx === "number") {
            tempStep.forEach((step) => {
                if (step.class === "current") {
                    step.class = "";
                }
            })
            tempStep[idx].class = "current";
        } else {
            for (let i = 0; i < tempStep.length; i++) {
                if (tempStep[i].class === "current" && i + 1 !== tempStep.length) {
                    tempStep[i + 1].class = "current";
                    tempStep[i].class = "";
                    break;
                }
            }
        }
        setSteps(tempStep);
        if (typeof idx === "string") {
            const data = {
                invoiceEmails: invoiceEmails.length > 0 ? invoiceEmails : undefined,
                memberEmails: memberEmails.length > 0 ? memberEmails : undefined,
                registerUrl: registerUrl,
                addMemberUrl: addMemberUrl
            }
            setOnborading(data);
        }
    }

    useEffect(() => {
        setMembersEmails([]);
        setInvoiceEmails([]);
        setSteps(allSteps);
        // eslint-disable-next-line
    }, [t, show])


    const removeImage = async () => {
        let updateUser: any = JSON.parse(JSON.stringify(user));
        updateUser.profilePic = "";
        dispatch(updateMeAction(updateUser));
        await updateCustomMeta({ key: "profilePic", value: "" });
        ActionActivity("user.profile.step1.title", "user.profile.title");
        setImage(undefined);
    };
    useEffect(() => {
        if (!image) {
            setImagePreview(undefined);
            return
        }
        let updateUser: any = JSON.parse(JSON.stringify(user));
        const objectUrl = URL.createObjectURL(image)
        setImagePreview(objectUrl);

        const formData = new FormData();
        formData.append('fileName', "profile");
        formData.append('file', image);

        uploadFile(formData, UploadedFrom.PROFILE)
            .then(async res => {
                if (res.data.url) {
                    await updateCustomMeta({ key: "profilePic", value: res.data.url });
                    updateUser.profilePic = res.data.url;
                    dispatch(updateMeAction(updateUser));
                }
            })
            .catch(err => console.log(err));

        return () => URL.revokeObjectURL(objectUrl)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image, dispatch, user.profilePic])

    const onImageSelect = e => {
        ActionActivity("user.profile.step1.title", "user.profile.title");
        if (!e.target.files || e.target.files.length === 0) {
            setImage(undefined);
            return;
        }
        setImage(e.target.files[0]);
    };

    useEffect(() => {
        if (imagePreview === undefined) {
            setImageUrl(user.profilePic ? user.profilePic : toAbsoluteUrl("/media/users/default.jpg"));
        }
        else {
            setImageUrl(imagePreview);
        }

    }, [imagePreview, user.profilePic])

    return (
        <Modal size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            style={{ zIndex: 9999 }} show={show} onHide={onHide} animation={true}>
            <Modal.Header closeButton>
                <Modal.Title>{t(`${title}`)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ position: "relative" }} className="d-flex flex-row py-lg-10 px-lg-10">
                    <div className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid" style={{ width: "40%" }} id="kt_modal_create_app_stepper" data-kt-stepper="true">
                        <div className="d-flex justify-content-center flex-row-auto w-100 w-xl-300px">
                            <div className="stepper-nav ps-lg-10">
                                {steps.map((step, idx, row) => (
                                    <div onClick={() => nextStep(idx)} key={idx} className={`stepper-item ${step.class}`} data-kt-stepper-element="nav">
                                        <div className="stepper-wrapper">
                                            <div className="stepper-icon w-40px h-40px">
                                                <i className="stepper-check fas fa-check"></i>
                                                <span className="stepper-number">{step.no}</span>
                                            </div>
                                            <div className="stepper-label">
                                                <h3 className="stepper-title">{step.title}</h3>
                                                <div className="stepper-desc">{step.desc}</div>
                                            </div>
                                        </div>
                                        {idx + 1 !== row.length && (
                                            <div className="stepper-line h-40px"></div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "60%" }} className="d-flex flex-column  align-items-left flex-row-fluid">
                        <form className="form fv-plugins-bootstrap5 fv-plugins-framework" no-validate="novalidate" id="kt_modal_create_app_form">
                            {steps[0].class === "current" &&
                                <div data-kt-stepper-element="content">
                                    <div className="w-100">
                                        <div className="fv-row mb-10 fv-plugins-icon-container">
                                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                                                <span className="onboard-step-title">{t("partials.onBoarding.addLogo")}</span>
                                                <Tooltip content={t("partials.onBoarding.personalizeText")} direction="top">
                                                    <InfoIcon />
                                                </Tooltip>

                                            </label>
                                            <div className="row">
                                                <div className="col-xl-6">
                                                    <div className="kt-avatar mb-2">
                                                        <div className="kt-avatar__holder filter-shadow" style={{ width: "250px", height: "222px", backgroundImage: `url('${imageUrl}')` }}></div>
                                                        <label className="kt-avatar__upload filter-shadow">
                                                            <i className="fa fa-pen"></i>
                                                            <input type="file" name="profile_avatar" accept=".png, .jpg, .jpeg" onChange={onImageSelect} />
                                                        </label>
                                                        <span style={{ display: "flex", height: "30px", width: "30px" }} className="kt-avatar__cancel filter-shadow" onClick={removeImage}>
                                                            <i className="fa fa-times"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <img width="400px" height="250px" src="https://thumbs.dreamstime.com/z/demo-computer-key-to-download-version-software-trial-64543995.jpg" />
                                            <div className="fv-plugins-message-container invalid-feedback"></div> */}
                                        </div>

                                    </div>
                                </div>}
                            {steps[1].class === "current" &&
                                <div data-kt-stepper-element="content">
                                    <div className="w-100">
                                        <div className="fv-row mb-10 fv-plugins-icon-container">
                                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                                                <span className="onboard-step-title">{t("partials.onBoarding.inviteTeamLabel")}</span>
                                                <Tooltip content={t("partials.onBoarding.inviteTeamInfo")} direction="top">
                                                    <InfoIcon />
                                                </Tooltip>
                                            </label>
                                            <EmailChips items={memberEmails} value={memberValue} error={membererror} setItems={setMembersEmails} setValue={setMemberValue} setError={setMemberError} placeHolder={t("partials.onBoarding.enterEmailAddress")} />
                                            {/* <input type="text" className="form-control form-control-solid" placeholder="" name="card_name" value="" /> */}
                                            <div className="fv-plugins-message-container invalid-feedback"></div>
                                        </div>
                                    </div>
                                </div>}

                            {steps[2].class === "current" &&
                                <div data-kt-stepper-element="content">
                                    <div className="w-100">
                                        <div className="fv-row mb-10 fv-plugins-icon-container">
                                            <label className="d-flex align-items-center fs-5 fw-bold">
                                                <span className="onboard-step-title">{t("partials.onBoarding.getInvoice")}</span>
                                                <Tooltip content={t("partials.onBoarding.enterRecipient")} direction="top">
                                                    <InfoIcon />
                                                </Tooltip>
                                            </label>
                                            <EmailChips items={invoiceEmails} value={invoiceValue} error={invoiceError} setItems={setInvoiceEmails} setValue={setInvoiceValue} setError={setInvoiceError} placeHolder={t("partials.onBoarding.enterEmailAddress")} />

                                            {/* <EmailChips  items={memberEmails} value={memberValue} error={membererror} placeHolder={t("partials.onBoarding.enterEmailAddress")} /> */}
                                            {/* <input type="text" className="form-control form-control-solid" placeholder="" name="card_name" value="" /> */}
                                            <div className="fv-plugins-message-container invalid-feedback"></div>
                                        </div>
                                    </div>
                                </div>}
                            {steps[3].class === "current" &&
                                <div data-kt-stepper-element="content">
                                    <div className="w-100">
                                        <div className="fv-row mb-10 fv-plugins-icon-container">
                                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                                                <span className="onboard-step-title">{t("partials.onBoarding.checklist")}</span>
                                                <Tooltip content={t("partials.onBoarding.callActionText")} direction="top">
                                                    <InfoIcon />
                                                </Tooltip>
                                            </label>
                                            <div className="scroll-chips" style={{ maxHeight: "233px" }}>
                                                <label className="d-flex flex-stack cursor-pointer mb-1">
                                                    <span className="d-flex align-items-center me-2">
                                                        <span className="d-flex flex-column">
                                                            <span className="onboard-step-title">{t("partials.onBoarding.step4Title1")}</span>
                                                            <div className="fs-7 text-muted">{t("partials.onBoarding.step4Desc1")}</div>
                                                        </span>
                                                    </span>
                                                </label>
                                                <label className="d-flex flex-stack cursor-pointer mb-1">
                                                    <span className="d-flex align-items-center me-2">
                                                        <span className="d-flex flex-column">
                                                            <span className="onboard-step-title">{t("partials.onBoarding.step4Title2")}</span>
                                                            <div className="fs-7 text-muted">{t("partials.onBoarding.step4Desc2")}</div>
                                                        </span>
                                                    </span>
                                                </label>
                                                <label className="d-flex flex-stack cursor-pointer mb-1">
                                                    <span className="d-flex align-items-center me-2">
                                                        <span className="d-flex flex-column">
                                                            <span className="onboard-step-title">{t("partials.onBoarding.step4Title3")}</span>
                                                            <div className="fs-7 text-muted">{t("partials.onBoarding.step4Desc3")}</div>
                                                        </span>
                                                    </span>
                                                </label>
                                                <label className="d-flex flex-stack cursor-pointer mb-1">
                                                    <span className="d-flex align-items-center me-2">
                                                        <span className="d-flex flex-column">
                                                            <span className="onboard-step-title">{t("partials.onBoarding.step4Title4")}</span>
                                                            <div className="fs-7 text-muted">{t("partials.onBoarding.step4Desc4")}</div>
                                                        </span>
                                                    </span>
                                                </label>
                                                <label className="d-flex flex-stack cursor-pointer mb-1">
                                                    <span className="d-flex align-items-center me-2">
                                                        <span className="d-flex flex-column">
                                                            <span className="onboard-step-title">{t("partials.onBoarding.step4Title5")}</span>
                                                            <div className="fs-7 text-muted">{t("partials.onBoarding.step4Desc5")}</div>
                                                        </span>
                                                    </span>
                                                </label>
                                                <label className="d-flex flex-stack cursor-pointer mb-1">
                                                    <span className="d-flex align-items-center me-2">
                                                        <span className="d-flex flex-column">
                                                            <span className="onboard-step-title">{t("partials.onBoarding.step4Title6")}</span>
                                                            <div className="fs-7 text-muted">{t("partials.onBoarding.step4Desc6")}</div>
                                                        </span>
                                                    </span>
                                                </label>
                                                <label className="d-flex flex-stack cursor-pointer mb-1">
                                                    <span className="d-flex align-items-center me-2">
                                                        <span className="d-flex flex-column">
                                                            <span className="onboard-step-title">{t("partials.onBoarding.step4Title7")}</span>
                                                            <div className="fs-7 text-muted">{t("partials.onBoarding.step4Desc7")}</div>
                                                        </span>
                                                    </span>
                                                </label>
                                                <label className="d-flex flex-stack cursor-pointer mb-1">
                                                    <span className="d-flex align-items-center me-2">
                                                        <span className="d-flex flex-column">
                                                            <span className="onboard-step-title">{t("partials.onBoarding.step4Title8")}</span>
                                                            <div className="fs-7 text-muted">{t("partials.onBoarding.step4Desc8")}</div>
                                                        </span>
                                                    </span>
                                                </label>
                                                <label className="d-flex flex-stack cursor-pointer mb-1">
                                                    <span className="d-flex align-items-center me-2">
                                                        <span className="d-flex flex-column">
                                                            <span className="onboard-step-title">{t("partials.onBoarding.step4Title9")}</span>
                                                            <div className="fs-7 text-muted">{t("partials.onBoarding.step4Desc9")}</div>
                                                        </span>
                                                    </span>
                                                </label>
                                            </div>
                                            {/* <input type="text" className="form-control form-control-solid" placeholder="" name="card_name" value="" /> */}
                                            <div className="fv-plugins-message-container invalid-feedback"></div>
                                        </div>
                                    </div>
                                </div>}
                            <div className="d-flex flex-stack pt-10">
                                <div>
                                    <button disabled={isLoading}
                                        className={`btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u btn-bottom`}
                                        onClick={() => nextStep(steps[3].class === "current" ? "current" : undefined)} type="button" style={{ width: "144px" }} data-kt-stepper-action="next">{t(steps[3].class === "current" ? "partials.onBoarding.send" : "partials.onBoarding.continue")}
                                    </button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </Modal.Body>
            {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
        </Modal.Footer> */}
        </Modal>
    )
}

export default OnBoarding;