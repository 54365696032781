/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
    format,
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    isSameDay,
    startOfYear,
    endOfYear
} from 'date-fns';
// import moment from "moment";
import { setFilterDate } from "src/app/store/data";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { useDispatch } from "react-redux";
//bootstrap
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { ActionActivity } from "src/app/utils/activityLog";
import { de, enGB } from "date-fns/locale";
import { useLocation } from "react-router-dom";

export const DateRangeContext = React.createContext<any>({});

const useStyles = makeStyles(() => ({
    buttonFix: {
        width: 170,
        float: 'left',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    btns: {
        display: 'inline-block'
    }
}));

const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfDay(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date()),
    startOfLastYear: startOfYear(new Date().setFullYear(new Date().getFullYear() - 1)),
    endOfLastYear: endOfYear(new Date().setFullYear(new Date().getFullYear() - 1)),
};

export default function CustomDateRangePicker() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [tUpdate, setTUpdate] = useState(0);
    const DRContext = useContext(DateRangeContext);
    const dispatch = useDispatch();
    const location = useLocation()

    useEffect(() => {
        setTUpdate(prev => prev + 1);
    }, [t]);

    const ranges = {
        [t('partials.dateRangePicker.today')]: [defineds.startOfToday, defineds.endOfToday],
        [t('partials.dateRangePicker.yesterday')]: [
            defineds.startOfYesterday,
            defineds.endOfYesterday,
        ],
        [t('partials.dateRangePicker.thisWeek')]: [
            defineds.startOfWeek,
            defineds.endOfWeek
        ],
        [t('partials.dateRangePicker.lastWeek')]: [
            defineds.startOfLastWeek,
            defineds.endOfLastWeek,
        ],
        [t('partials.dateRangePicker.thisMonth')]: [
            defineds.startOfMonth,
            defineds.endOfMonth,
        ],
        [t('partials.dateRangePicker.lastMonth')]: [
            defineds.startOfLastMonth,
            defineds.endOfLastMonth,
        ],
        [t('partials.dateRangePicker.thisYear')]: [
            defineds.startOfYear,
            defineds.endOfYear,
        ],
        [t('partials.dateRangePicker.lastYear')]: [
            defineds.startOfLastYear,
            defineds.endOfLastYear,
        ]
    }

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    useEffect(() => {
        const date = [{
            from_date: format(new Date(state[0].startDate), "yyyy-MM-dd"),
            to_date: format(new Date(state[0].endDate), "yyyy-MM-dd"),
            // key: 'selection'
        }]
        dispatch(setFilterDate(date));
    }, [state, dispatch]);
    // const dateChange = (type: string) => {
    //     let fromDate, toDate;
    //     if (type === 'today') {
    //         DRContext.callBack({
    //             from_date: format(defineds.startOfToday, "yyyy-MM-dd"),
    //             to_date: format(defineds.endOfToday, "yyyy-MM-dd")
    //         });
    //         fromDate = format(defineds.startOfToday, "yyyy-MM-dd");
    //         toDate = format(defineds.endOfToday, "yyyy-MM-dd");
    //     }

    //     if (type === 'month') {
    //         DRContext.callBack({
    //             from_date: moment(state[0].startDate).startOf('month').format("YYYY-MM-DD"),
    //             to_date: moment().format("YYYY-MM-DD")
    //         });
    //         fromDate = moment(state[0].startDate).startOf('month').format("YYYY-MM-DD");
    //         toDate = moment().format("YYYY-MM-DD");
    //     }

    //     if (type === 'year') {
    //         DRContext.callBack({
    //             from_date: format(defineds.startOfYear, "yyyy-MM-dd"),
    //             to_date: moment().format("YYYY-MM-DD")
    //         });
    //         fromDate = format(defineds.startOfYear, "yyyy-MM-dd");
    //         toDate = moment().format("YYYY-MM-DD");
    //     }

    //     ActionActivity("topbar.sub.dateRange");

    //     setState([{
    //         startDate: new Date(fromDate),
    //         endDate: new Date(toDate),
    //         key: 'selection'
    //     }])
    // };

    const displaySelectedRange = (start: Date, end: Date) => {
        if (isSameDay(start, end)) {
            return `${format(start, i18n.language === "de" ? "MMM. dd" : "MMM dd", { locale: i18n.language === "de" ? de : enGB })}`;
        }
        else {
            return `${format(start, i18n.language === "de" ? "MMM. dd" : "MMM dd", { locale: i18n.language === "de" ? de : enGB })} - ${format(end, i18n.language === "de" ? "MMM. dd" : "MMM dd", { locale: i18n.language === "de" ? de : enGB })}`;
        }
    };

    const handleDateRangeCallBack = (start, end) => {
        setState([{
            startDate: new Date(start),
            endDate: new Date(end),
            key: 'selection'
        }]);
        ActionActivity("topbar.sub.dateRange");
        if (location.pathname !== "/dashboard") {
            DRContext.callBack({
                from_date: format(new Date(start), "yyyy-MM-dd"),
                to_date: format(new Date(end), "yyyy-MM-dd")
            });
        }
        DRContext.setDateRange({
            from_date: format(new Date(start), "yyyy-MM-dd"),
            to_date: format(new Date(end), "yyyy-MM-dd")
        });
    }

    return (
        <>
            {
                DRContext.active === true ?
                    (
                        <div className={classes.btns}>

                            {/* <button type="button" className="btn kt-subheader__btn-primary" onClick={() => dateChange('today')}>
                                {t('partials.dateRangePicker.today')}
                            </button>
                            <button type="button" className="btn kt-subheader__btn-primary" onClick={() => dateChange('month')}>
                                {t('partials.dateRangePicker.month')}
                            </button>
                            <button type="button" className="btn kt-subheader__btn-primary" onClick={() => dateChange('year')}>
                                {t('partials.dateRangePicker.year')}
                            </button> */}
                            <DateRangePicker key={tUpdate}
                                initialSettings={{
                                    ranges: ranges,
                                    opens: "left",
                                    locale: {
                                        customRangeLabel: t('partials.dateRangePicker.custom'),
                                        applyLabel: t('partials.dateRangePicker.applyLabel'),
                                        cancelLabel: t('partials.dateRangePicker.cancelLabel')
                                    },
                                    applyButtonClasses: "bg-blue-gradient"
                                }}
                                onCallback={handleDateRangeCallBack}

                            >
                                <div className="btn kt-subheader__btn-primary" style={{marginRight: '0.25rem'}}>
                                    <span className="mr-2"> {displaySelectedRange(state[0].startDate, state[0].endDate)}</span>
                                    <i className="flaticon2-calendar-1"></i>
                                </div>
                            </DateRangePicker>
                        </div>)
                    : ""
            }
        </>
    );
}