import React, { useEffect, useState } from "react";
// import UserNotifications from "../../../app/partials/layout/UserNotifications";
import LanguageSelector from "../../../app/partials/layout/LanguageSelector";
import UserProfile from "../../../app/partials/layout/UserProfile";
import ProfileHistory from "../../../app/partials/layout/ProfileHistory";
// import { toAbsoluteUrl } from "../../utils/utils";
import { ReactComponent as DefaultIcon } from "../assets/layout-svg-icons/set-default.svg";

import { updateCustomMeta } from "src/app/crud/auth.crud";
// import { postOnboardingInvitation } from "src/app/crud/onBoarding.crud";
// import { updateMe as updateMeAction } from "src/app/store/auth";
import { useSelector, useDispatch } from "react-redux";
import { setNotification } from "src/app/store/data";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ActionModalActivity } from "src/app/utils/activityLog";
import OnBoarding from "../../../app/partials/layout/onBoardingModal";
// import { setActiveJobs } from "src/app/store/data";
// import { ActionActivity } from "src/app/utils/activityLog";
import { isEmptyObject } from "jquery";

export default function Topbar() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user && state.auth.user.parent ? state.auth.user.parent : state.auth.user);
    const [showDefault, setShowDefault] = useState(true);
    const [show, setShow] = useState(false);
  
    // const [isLoading, setIsLoading] = useState(false);
    // const jobsData = useSelector((state) => state.data.jobs);
    // const [onBoarding, setOnborading] = useState(user.onBoardingProcess ? user.onBoardingProcess : false);


    const handleClose = () => {
        setShow(false);
    }

    // const sendOnBoarding = async (data) => {
    //     setIsLoading(true);
    //     try {
    //         await postOnboardingInvitation(data).then((boardingProcess) => {
    //             if (boardingProcess && boardingProcess.status === 200) {
    //                 dispatch(setNotification({ type: "success", title: 'general.success', message: 'partials.onBoarding.onBoardingStarted' }));
    //                 ActionActivity("aside.dashboard");
    //                 if (Array.isArray(jobsData)) {
    //                     const temp = jobsData;
    //                     temp.push(boardingProcess.data);
    //                     dispatch(setActiveJobs(temp));
    //                 }
    //                 else {
    //                     dispatch(setActiveJobs([boardingProcess.data]));
    //                 }
    //             }
    //         });
    //         setOnborading(true);
    //         setIsLoading(false);
    //         handleClose();
    //     } catch (e) {
    //         setIsLoading(false);
    //         handleClose();
    //     }

    // };

    useEffect(() => {
        if (user && (user.landingPage === window.location.pathname)) {
            setShowDefault(false);
        }
        else {
            setShowDefault(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, window.location.pathname]);

    const onSetDefaultPage = () => {
        updateCustomMeta({ key: "landingPage", value: window.location.pathname })
            .then(async (response) => {
                // const userResponse = await getMe();
                // dispatch(updateMeAction(userResponse.data));
                ActionModalActivity("partials.landing.title");
                setShowDefault(false);
                dispatch(setNotification({ type: "success", title: 'general.info', message: 'general.defaultSuccess' }));
            });
    }

    return (
        <>
            <style>
            {`
            .tooltip-inner {
                background: linear-gradient(150deg, rgb(70, 190, 247) 0%, rgb(83, 133, 225) 100%); !important;
                color: white;
            }

            .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
                left: 0;
                border-width: 0.4rem 0 0.4rem 0.4rem;
                border-left-color: rgb(83, 133, 225) !important;
            }

            `}
            </style>
            <div className="kt-header__topbar">
                {showDefault ?
                    <div className="kt-header__topbar-item kt-header__topbar-item--quick-panel" onClick={onSetDefaultPage}>
                        <OverlayTrigger placement="left" overlay={<Tooltip id="user-notification-tooltip">{t('general.defaultText')}</Tooltip>} >
                            <span
                                className={isEmptyObject(user.sub)
                                    ? "topbarSvgFlowerIcon kt-header__topbar-icon svg g  kt-header__topmg"
                                    : "kt-header__topbar-icon svg g  kt-header__topmg"
                                }
                            >
                                <DefaultIcon />
                            </span>
                        </OverlayTrigger>
                    </div>

                    : ""
                }

                <ProfileHistory />

                {/* <UserNotifications
                bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}
                pulse="true"
                pulseLight="false"
                skin="dark"
                iconType=""
                type="success"
                useSVG="true"
                dot="false"
            /> */}

                <LanguageSelector iconType="" />

                <UserProfile showAvatar={true} showHi={false} showBadge={false} />
                <OnBoarding show={show} onHide={handleClose} title={"Onboarding"} />

                {/* setOnborading={(data) => sendOnBoarding(data)} */}
                {/* isLoading={isLoading} */}
            </div>
        </>

    );

}