import { makeStyles } from "@material-ui/core";

const CompanyModalStyle = makeStyles((theme) => ({
    modal: {
        "& > .modal-dialog": {
            maxWidth: 650,
        },
        "& .modal-body": {
            padding: "3rem 2.5rem",
            height: "calc(100vh - 14rem)",
            overflow: "hidden",
            "& .scrollbar-container": {
                padding: "0 1.5rem",
                height: "100%",
            },
        },
        "& option[disabled]": {
            color: theme.palette.grey[500],
        },
    },
    accordionToggle: {
        cursor: "pointer",
        "& *": {
            transition: "all .3s ease-in-out",
        },
        "& .hide": {
            transform: "rotate(180deg)",
        },
    },
    contactPersonWrapper: {
        borderBottom: "1px solid #e2e5ec",
        paddingBottom: "1.5rem",
        marginBottom: "1.4rem",
        "&:last-child": {
            borderBottom: "0",
            paddingBottom: "0",
            marginBottom: "0",
        },
    },
}));

export default CompanyModalStyle;
